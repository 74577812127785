import { useResize } from "@/utils/useResize";
import {
	ErrorModal,
	ShiftDayNavigatorControl,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Box,
	CircularProgress,
	Divider,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import {
	getCasterChemistries,
	getGanttData,
} from "../caster/repositories/ProductionRepository";
import { getDelaysByShift } from "../delays/delays-manager/repositories/DelaysManagerRepository";
import { getEntries } from "../logbook/logbook-entry/repository/LogbookRepository";
import { GanttSection } from "../meltshop-dashboard/components/GanttSection/GanttSection";
import { useGanttUpdater } from "../meltshop-dashboard/hooks/useGanttUpdater";
import { CasterSummaryTable } from "./components/tables/caster-summary/CasterSummaryTable";
import { DelaysTable } from "./components/tables/delays-table/DelaysTable";
import { EAFSummaryTable } from "./components/tables/eaf-summary/EAFSummaryTable";
import { HeatsTable } from "./components/tables/heats-table/HeatsTable";
import { LogbookTable } from "./components/tables/logbook-table/LogbookTable";
import { MissedChemistriesTable } from "./components/tables/missed-chemistries-table/MissedChemistriesTable";
import { ProductionSummaryTable } from "./components/tables/prd-summary/ProductionSummaryTable";
import { QualitySummaryTable } from "./components/tables/quality-summary/QualitySummary";
import { useShiftReportContext } from "./context/ShiftReportContext";
import {
	getCASSummary,
	getEAFSummary,
	getHeatsForShiftReport,
	getProductionSummary,
	getQualitySummary,
	getTopFiveDelays,
} from "./repository/ShiftReportRepository";

export const ShiftReportPage = () => {
	const { state, actions } = useShiftReportContext();
	const { shiftInfo, initShiftNavigator, getShiftDataFromAPI } =
		useShiftNavigator();
	const { width } = useResize();
	const colWidth = (width * 0.9) / 12;
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [error, setError] = useState<string>("");
	const { assets, updateGantt } = useGanttUpdater(shiftInfo);

	const [lastShiftId, setLastShiftId] = useState<number | null>(null);

	const [isLoadingEAFSummary, setIsLoadingEAFSummary] = useState(true);
	const [isLoadingCASSummary, setIsLoadingCASSummary] = useState(true);
	const [isLoadingQualitySummary, setIsLoadingQualitySummary] = useState(true);
	const [isLoadingHeats, setIsLoadingHeats] = useState(true);
	const [isLoadingDelays, setIsLoadingDelays] = useState(true);
	const [isLoadingPRDSummary, setIsLoadingPRDSummary] = useState(true);
	const [isLoadingLogbook, setIsLoadingLogbook] = useState(true);
	const [isLoadingMissedChemistries, setIsLoadingMissedChemistries] =
		useState(true);
	const [isLoadingGantt, setIsLoadingGantt] = useState(true);

	useEffect(() => {
		getShiftDataFromAPI(null, 0).catch((err) => {
			setError(err?.message || "Error loading shift data");
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchShiftReportData = async (shiftId: number) => {
		setIsLoadingEAFSummary(true);
		setIsLoadingCASSummary(true);
		setIsLoadingQualitySummary(true);
		setIsLoadingHeats(true);
		setIsLoadingDelays(true);
		setIsLoadingPRDSummary(true);
		setIsLoadingLogbook(true);
		setIsLoadingMissedChemistries(true);
		setIsLoadingGantt(true);
		const errors: string[] = [];

		const promises = {
			eafSummary: getEAFSummary(shiftId),
			casSummary: getCASSummary(shiftId),
			qualitySummary: getQualitySummary(shiftId),
			heats: getHeatsForShiftReport(shiftId),
			delays: getTopFiveDelays(shiftId, null),
			prdSummary: getProductionSummary(shiftId),
			logbook: getEntries(null, "", null, shiftId),
			missedChemistries: getCasterChemistries({
				startDate: null,
				endDate: null,
				shiftId: shiftId,
				gradeId: 0,
				billetSizeId: 0,
				includeExternalHeats: false,
			}),
			ganttData: shiftInfo
				? getGanttData(shiftInfo.CurrentStart, shiftInfo.CurrentEnd)
				: Promise.resolve({ ok: false, message: "No shiftInfo available" }),
		};

		const keys = Object.keys(promises);
		const results = await Promise.allSettled(Object.values(promises));

		results.forEach((res, i) => {
			const key = keys[i];
			if (res.status === "fulfilled") {
				const response = res.value;
				if (response.ok) {
					switch (key) {
						case "eafSummary":
							actions.setEafSummary(get(response, "data.tables[0].rows", null));
							setIsLoadingEAFSummary(false);
							break;
						case "casSummary":
							actions.setCasSummary(get(response, "data.tables[0].rows", null));
							setIsLoadingCASSummary(false);
							break;
						case "qualitySummary":
							actions.setQualitySummary(
								get(response, "data.tables[1].rows[0]", null),
							);
							setIsLoadingQualitySummary(false);
							break;
						case "ganttData":
							const ganttData = get(response, "data", null);
							if (ganttData) {
								updateGantt(ganttData);
							}
							setIsLoadingGantt(false);
							break;
						case "heats":
							actions.setHeats(get(response, "data.tables[0].rows", null));
							setIsLoadingHeats(false);
							break;
						case "prdSummary":
							actions.setPrdSummary(get(response, "data.tables[0].rows", null));
							setIsLoadingPRDSummary(false);
							break;
						case "delays":
							actions.setDelays(get(response, "data.tables[0].rows", null));
							setIsLoadingDelays(false);
							break;
						case "missedChemistries":
							actions.setMissedChemistries(
								get(response, "data.tables[0].rows", null),
							);
							setIsLoadingMissedChemistries(false);
							break;
						case "logbook":
							actions.setLogbook(get(response, "data.tables[0].rows", null));
							setIsLoadingLogbook(false);
							break;
						default:
							break;
					}
				} else {
					errors.push(response.message);
				}
			} else {
				errors.push((res.reason && res.reason.message) || "Unknown error");
			}
		});

		if (errors.length > 0) {
			setError(errors.join(" | "));
		} else {
			setError("");
		}

		setIsLoading(false);
	};

	useEffect(() => {
		const shiftId = get(shiftInfo, "CurrentShiftId", null);
		if (!shiftId) return;

		if (shiftId !== lastShiftId) {
			fetchShiftReportData(shiftId);
			setLastShiftId(shiftId);
		}
	}, [shiftInfo, lastShiftId, actions, updateGantt]);

	return (
		<>
			<Grid container spacing={1} style={{ padding: "20px" }}>
				<Grid item md={12} xs={12}>
					<Typography
						variant="h4"
						style={{
							marginLeft: "10px",
							fontWeight: 600,
							userSelect: "none",
						}}
					>
						Shift Report
					</Typography>
				</Grid>

				{/* Navigator */}
				<Grid
					item
					container
					xs={12}
					justifyContent="center"
					alignItems="center"
				>
					<ShiftDayNavigatorControl preventInit />
				</Grid>

				{/* Tablas de resumen */}
				<Grid item container spacing={2} xs={12}>
					<Grid item xs={4}>
						<EAFSummaryTable
							rows={state.eafSummary}
							isLoading={isLoadingEAFSummary}
						/>
					</Grid>
					<Grid item xs={4}>
						<CasterSummaryTable
							rows={state.casSummary}
							isLoading={isLoadingCASSummary}
						/>
					</Grid>
					<Grid item xs={4}>
						<QualitySummaryTable
							rows={state.qualitySummary}
							isLoading={isLoadingQualitySummary}
						/>
					</Grid>
				</Grid>
				<Divider style={{ margin: "10px" }} />

				{/* Gantt */}
				<Grid item xs={12}>
					<Paper>
						<Box padding={3} style={{ position: "relative" }}>
							<Typography variant="h4">Gantt</Typography>
							{shiftInfo && assets.length > 0 && (
								<GanttSection
									key={`shift-${shiftInfo?.CurrentShiftId}`}
									columnWidth={colWidth}
									assets={assets}
									showArrowTriangle={false}
									startCalendarDate={shiftInfo?.CurrentStart}
									endCalendarDate={shiftInfo?.CurrentEnd}
									rowHeight={150}
									barFill={50}
									lineSectionFill={40}
									lineFill={40}
									refreshData={() =>
										fetchShiftReportData(shiftInfo?.CurrentShiftId)
									}
									withoutContextMenu
								/>
							)}
							{isLoadingGantt && (
								<CircularProgress
									style={{
										position: "absolute",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								/>
							)}
						</Box>
					</Paper>
				</Grid>

				{/* Heats */}
				<Grid item container xs={12}>
					<Grid item xs={12}>
						<Paper>
							<Box padding={3}>
								<Typography variant="h4">Heats</Typography>
								<HeatsTable heats={state.heats} isLoading={isLoadingHeats} />
							</Box>
						</Paper>
					</Grid>
				</Grid>

				{/* Delays & Missed Chemistries */}
				<Grid item container xs={12}>
					<Grid item xs={6}>
						<Paper>
							<Box padding={3}>
								<Typography variant="h4">Production Summary</Typography>
								<ProductionSummaryTable
									rows={state.prdSummary}
									isLoading={isLoadingPRDSummary}
								/>
							</Box>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper>
							<Box padding={3}>
								<Typography variant="h4">Missed Chemistries</Typography>
								<MissedChemistriesTable
									rows={state.missedChemistries}
									isLoading={isLoadingMissedChemistries}
								/>
							</Box>
						</Paper>
					</Grid>
				</Grid>

				{/* Delays */}
				<Grid item container xs={12}>
					<Grid item xs={12}>
						<Paper>
							<Box padding={3}>
								<Typography variant="h4" style={{ paddingBottom: "10px" }}>
									Top 5 Delays by Asset
								</Typography>
								<DelaysTable rows={state.delays} isLoading={isLoadingDelays} />
							</Box>
						</Paper>
					</Grid>
				</Grid>

				{/* Logbook */}
				<Grid item container xs={12}>
					<Grid item xs={12}>
						<Paper>
							<Box padding={3}>
								<Typography variant="h4">Logbook</Typography>
								<LogbookTable
									rows={state.logbook}
									isLoading={isLoadingLogbook}
								/>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
			<ErrorModal error={error} onHide={() => setError("")} />
		</>
	);
};
