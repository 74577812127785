import { useShiftNavigator } from "@dexteel/mesf-core";
import { get } from "react-hook-form";
import { useLogbookContext } from "../context/logbookContext";
import { getEntries } from "../repository/LogbookRepository";

type Props = {
	setIsLoading: (isLoading: boolean) => void;
	setError: (err: string) => void;
};

export const useSearchEntries = ({ setIsLoading, setError }: Props) => {
	const {
		state: {
			FilterParameters: { SearchText, AssetId, SearchShiftParameters },
		},
		actions: { setEntriesList, setLoadingTable },
	} = useLogbookContext();
	return async () => {
		setIsLoading(true);
		setLoadingTable(true);

		const res = await getEntries(
			null,
			SearchText,
			AssetId,
			SearchShiftParameters?.StartShiftId || null,
			SearchShiftParameters?.EndShiftId || null,
		);
		if (res.ok) {
			const rowsSource = get(res, "data", []);
			const dataTable = rowsSource.tables[0];

			setEntriesList(dataTable.rows);
		} else setError(res.message);
		setIsLoading(false);
		setLoadingTable(false);
	};
};
