import { EafDataEntryPage } from "./DataEntryPage";
import { EAFDataEntryProvider } from "./context/reducer.provider";
import { TimerProvider } from "./context/timer.provider";

import { ThemeProvider } from "@material-ui/core";
import theme from "./theme/theme";

type Props = {};
const EAFDataEntry = (props: Props) => {
	return (
		<EAFDataEntryProvider>
			<TimerProvider>
				<div
					id={"dummy"}
					style={{ height: "0", width: "0" }}
					tabIndex={-1}
				></div>
				<ThemeProvider theme={theme}>
					<EafDataEntryPage />
				</ThemeProvider>
			</TimerProvider>
		</EAFDataEntryProvider>
	);
};
export default EAFDataEntry;
