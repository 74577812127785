import { Entry } from "@/pages/logbook/models/Entry";
import { useTablesStyles } from "@/pages/shift-report/styles/useTablesStyles";
import { Box, Paper } from "@material-ui/core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { useTableData } from "./hooks/useTableData";

interface Props {
	rows: Entry[];
	isLoading: boolean;
}

export const LogbookTable = ({ rows, isLoading }: Props) => {
	const classes = useTablesStyles();
	const { columns, defaultColDef } = useTableData();
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
	};
	useEffect(() => {
		if (gridAPI) {
			if (isLoading) gridAPI.showLoadingOverlay();
			else gridAPI.hideOverlay();
		}
	}, [gridAPI, isLoading]);

	return (
		<Paper className={classes.root} elevation={0}>
			<Box className={`ag-theme-balham ${classes.smAgGridBox}`}>
				<AgGridReact
					rowSelection="single"
					suppressRowClickSelection={true}
					pagination={false}
					rowHeight={50}
					headerHeight={35}
					singleClickEdit={false}
					tooltipShowDelay={0}
					tooltipHideDelay={2000}
					rowData={rows}
					columnDefs={columns}
					defaultColDef={defaultColDef}
					domLayout="normal"
					onGridReady={onGridReady}
				/>
			</Box>
		</Paper>
	);
};
