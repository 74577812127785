import { MESApiService, getMomentTz, spParameter } from "@dexteel/mesf-core";

import { get, isBoolean } from "lodash-es";
import {
	CasterProductionFilter,
	MeltShopProductionShiftFilter,
} from "../models/Filters";
import { AlertConfiguration } from "../models/catalogs/alertConfiguration";
import { HeatReconciliation } from "../models/catalogs/heatReconciliation";

const moment = getMomentTz();

export const exportCasterProductions = async (
	parameters: CasterProductionFilter,
) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "@StartDate",
			value: JSON.parse(JSON.stringify(parameters.startDate)),
		});
		sqlParameters.push({
			name: "@EndDate",
			value: JSON.parse(JSON.stringify(parameters.endDate)),
		});
		sqlParameters.push({
			name: "@BilletSizeId",
			value: parameters.billetSizeId === 0 ? null : parameters.billetSizeId,
		});
		sqlParameters.push({
			name: "@GradeId",
			value: parameters.gradeId === 0 ? null : parameters.gradeId,
		});
		sqlParameters.push({
			name: "@ShiftId",
			value: parameters.shiftId === 0 ? null : parameters.shiftId,
		});
		sqlParameters.push({ name: "@ToJSON", value: false });

		const apiService = new MESApiService();
		const structureSheet = {
			showAllSheets: true,
			sheets: [
				{
					order: 1,
					resultSetIndex: 0,
					sheetName: "Caster Production",
					showAllColumns: false,
					columns: [
						{
							columnName: "HeatName.Value",
							caption: "Heat",
							order: 1,
						},
						{
							columnName: "Grade.Value",
							caption: "Grade",
							order: 2,
						},
						{
							columnName: "BilletSizeName",
							caption: "Billet Size",
							order: 3,
						},
						{
							columnName: "Open.Free.Value",
							caption: "Open Free",
							order: 4,
						},
						{
							columnName: "Open.Burn.Value",
							caption: "Open Burn",
							order: 5,
						},
						{
							columnName: "NozzleSize",
							caption: "Nozzle",
							order: 6,
						},
						{
							columnName: "Trim.Temperature",
							caption: "Ladle Trim Temp",
							order: 7,
						},
						{
							columnName: "Trim.SuperHeat.Value",
							caption: "Ladle Trim SHeat",
							order: 8,
						},
						{
							columnName: "Tundish.Number",
							caption: "Tundish Num",
							order: 9,
						},
						{
							columnName: "Tundish.Sequence",
							caption: "Tundish Seq",
							order: 10,
						},
						{
							columnName: "Temperatures.Liquidus",
							caption: "Temperatures Liquidus",
							order: 11,
						},
						{
							columnName: "Temperatures.Minimum",
							caption: "Temperatures Minimum",
							order: 12,
						},
						{
							columnName: "Temperatures.Maximum",
							caption: "Temperatures Maximum",
							order: 13,
						},
						{
							columnName: "Temperatures.MinSH.Value",
							caption: "Temperatures MinSH",
							order: 14,
						},
						{
							columnName: "LadleOpen",
							caption: "Times Open",
							order: 15,
						},
						{
							columnName: "LadleClose",
							caption: "Times Close",
							order: 16,
						},
						{
							columnName: "Duration.Value",
							caption: "Times Duration",
							order: 17,
						},
						{
							columnName: "AvgSpeed.S1.Value",
							caption: "Avg Speed S1",
							order: 18,
						},
						{
							columnName: "AvgSpeed.S2.Value",
							caption: "Avg Speed S2",
							order: 19,
						},
						{
							columnName: "AvgSpeed.S3.Value",
							caption: "Avg Speed S3",
							order: 20,
						},
						{
							columnName: "Products.Pieces",
							caption: "Products Pieces",
							order: 21,
						},
						{
							columnName: "Products.Tons",
							caption: "Products Tons",
							order: 22,
						},
						{
							columnName: "Scrap.Pieces",
							caption: "Scrap Pieces",
							order: 23,
						},
						{
							columnName: "Scrap.Length",
							caption: "Scrap Length",
							order: 24,
						},
						{
							columnName: "Comments",
							caption: "Comments",
							order: 25,
						},
					],
				},
			],
		};
		const resp = await apiService.exportExcel(
			"[CAS].[GetProductionGridJSON]",
			sqlParameters,
			"Caster Production.xlsx",
			structureSheet,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getCatalogs = async () => {
	try {
		const apiService = new MESApiService();
		const resp = await apiService.callJSON("[CAS].[GetCatalogsJSON]", []);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp.result,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getShiftByParameters = async (
	parameters: MeltShopProductionShiftFilter,
) => {
	const utcString = parameters.productionDate?.toISOString();

	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "@ShiftId",
			value: parameters.shiftId === 0 ? null : parameters.shiftId,
		});
		sqlParameters.push({
			name: "@ProductionDate",
			value: utcString,
		});
		const apiService = new MESApiService();
		const resp = await apiService.callV2(
			"[MES].[GetShiftByParameters]",
			sqlParameters,
		);

		if (resp.ok) {
			return {
				ok: true,
				data: get(resp.data, "tables[0].rows[0]", {}),
			};
		} else {
			return {
				ok: false,
				message: resp.message,
			};
		}
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
// Caster Dashboard
export const getCasterProductions = async (
	parameters: CasterProductionFilter,
) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "@StartDate",
			value: JSON.parse(JSON.stringify(parameters.startDate)),
		});
		sqlParameters.push({
			name: "@EndDate",
			value: JSON.parse(JSON.stringify(parameters.endDate)),
		});
		sqlParameters.push({
			name: "@BilletSizeId",
			value: parameters.billetSizeId === 0 ? null : parameters.billetSizeId,
		});
		sqlParameters.push({
			name: "@GradeId",
			value: parameters.gradeId === 0 ? null : parameters.gradeId,
		});
		sqlParameters.push({
			name: "@ShiftId",
			value: parameters.shiftId === 0 ? null : parameters.shiftId,
		});

		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[CAS].[GetProductionGrid]",
			sqlParameters,
		);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const getCasterChemistries = async (
	parameters: CasterProductionFilter,
) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "@StartDate",
			value: JSON.parse(JSON.stringify(parameters.startDate)),
		});
		sqlParameters.push({
			name: "@EndDate",
			value: JSON.parse(JSON.stringify(parameters.endDate)),
		});
		sqlParameters.push({
			name: "@BilletSizeId",
			value: parameters.billetSizeId === 0 ? null : parameters.billetSizeId,
		});
		sqlParameters.push({
			name: "@GradeId",
			value: parameters.gradeId === 0 ? null : parameters.gradeId,
		});
		sqlParameters.push({
			name: "@ShiftId",
			value: parameters.shiftId === 0 ? null : parameters.shiftId,
		});
		sqlParameters.push({
			name: "@IncludeExternalHeats",
			value: !isBoolean(parameters.includeExternalHeats)
				? null
				: parameters.includeExternalHeats,
		});

		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[LAB].[GetMissedChemistriesGrid]",
			sqlParameters,
		);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
//Caster Heat Report
export const getCasterHeatReport = async (processId: number) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({ name: "@ProcessId", value: processId });

		const apiService = new MESApiService();
		const resp = await apiService.call("[CAS].[GetHeatReport]", sqlParameters);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
//
export const searchHeatName = async (searchText: string) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({ name: "@SearchText", value: searchText.trim() });

		const apiService = new MESApiService();
		const resp = await apiService.callJSON(
			"[CAS].[SearchHeatNamesJSON]",
			sqlParameters,
		);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp.result,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const getChemistryHeatReport = async (heatId: number) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({ name: "@HeatId", value: heatId });

		const apiService = new MESApiService();
		const resp = await apiService.callJSON(
			"[LAB].[GetHeatReport]",
			sqlParameters,
		);

		if (resp.errorMessage !== null) {
			return { ok: false, message: resp.errorMessage };
		}

		if (resp.result.rows) {
			resp.result.rows = resp.result.rows.map((row: any) => ({
				...row,
				ProductionDate: row["ProductionDate"]
					? moment.utc(row["ProductionDate"]).toDate()
					: null,
				Time: {
					...row.Time,
					Value: row.Time["Value"]
						? moment.utc(row.Time["Value"]).toDate()
						: null,
				},
			}));
		}

		return {
			ok: true,
			data: resp.result,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const saveHeatReconciliation = async (heat: HeatReconciliation) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({ name: "@ProcessId", value: heat.ProcessId });
		sqlParameters.push({ name: "@HeatName", value: heat.HeatName });
		sqlParameters.push({ name: "@ProductionYear", value: heat.ProductionYear });
		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[CAS].[ChangeHeatToProcess]",
			sqlParameters,
		);
		if (apiService.hasErrors) {
			return { ok: false, error: apiService.error };
		}
		return {
			ok: true,
			data: resp.tables,
		};
	} catch (e: any) {
		return { ok: false, error: e };
	}
};
export const getProcessesList = async (
	heatName: string,
	productionDate: Date | null,
) => {
	try {
		const sqlParameters = [];
		if (heatName.length > 0)
			sqlParameters.push({ name: "@HeatName", value: heatName });
		if (productionDate != null)
			sqlParameters.push({ name: "@ProductionDate", value: productionDate });

		const apiService = new MESApiService();
		const resp = await apiService.callJSON(
			"[CAS].[GetProcessesListJSON]",
			sqlParameters,
		);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp.result,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getSectionsPerMoldSize = async (MoldSizeId: number | null) => {
	const apiService = new MESApiService();
	const resp = await apiService.callV2("[CAS].[GetSectionsPerMoldSize]", [
		{ name: "@MoldSizeId", value: MoldSizeId },
	]);
	return resp;
};

//Deprecated
export const GetAlertConfiguration = async () => {
	try {
		const apiService = new MESApiService();
		const resp = await apiService.call("[CAS].[GetAlertConfiguration]", []);
		if (apiService.hasErrors) {
			return { ok: false, error: apiService.error };
		}
		return {
			ok: true,
			data: resp.tables,
		};
	} catch (e: any) {
		return { ok: false, error: e };
	}
};
export const saveAlertConfiguration = async (model: AlertConfiguration) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({
			name: "@AlertConfigurationId",
			value: model.AlertConfigurationId,
		});
		sqlParameters.push({ name: "@Value", value: model.Value });
		sqlParameters.push({ name: "@CssClass", value: model.CssClass });
		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[CAS].[UpsertAlertConfiguration]",
			sqlParameters,
		);
		if (apiService.hasErrors) {
			return { ok: false, error: apiService.error };
		}
		return {
			ok: true,
			data: resp.tables,
		};
	} catch (e: any) {
		return { ok: false, error: e };
	}
};

//Caster Processes
export const getProcessesByShift = async (
	shiftId: number,
	deleted: boolean,
) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "@ShiftId", value: shiftId });
	sqlParameters.push({ name: "@GetDeleted", value: deleted });
	try {
		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[CAS].[GetProcessesByShift]",
			sqlParameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const upsertProcess = async (
	processId: number | null,
	ladleOpen: Date | null,
	ladleClose: Date | null,
) => {
	try {
		const apiService = new MESApiService();
		const parameters: spParameter[] = [];
		parameters.push({ name: "ProcessId", value: processId });
		parameters.push({
			name: "Start",
			value: ladleOpen === null ? null : ladleOpen.toISOString(),
		});
		parameters.push({
			name: "End",
			value: ladleClose === null ? null : ladleClose.toISOString(),
		});
		parameters.push({ name: "IsProcessManual", value: true });

		return await apiService.callV2("[CAS].[UpsertProcess]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const deleteProcess = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		return await apiService.callV2("[CAS].[DeleteProcess]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const unDeleteProcess = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		return await apiService.callV2("[CAS].[UnDeleteProcess]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const unLinkProcess = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		return await apiService.callV2("[CAS].[UnlinkProcessToHeat]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const linkCasterProcess = async (processId: number, heatId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: "HeatId", value: heatId });
	parameters.push({ name: "IsHeatIdManual", value: true });
	try {
		return await apiService.callV2(
			"[CAS].[ManualLinkProcessToHeat]",
			parameters,
		);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const searchUnlinkedHeats = async (
	searchText: string,
	ladleOpen: Date,
) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	parameters.push({
		name: "SearchText",
		value: searchText,
	});

	parameters.push({
		name: "LadleOpen",
		value: ladleOpen,
	});
	const resp = await apiService.callV2(
		"[CAS].[SearchUnlinkedHeats]",
		parameters,
	);
	return resp;
};

//Caster Data Entry
export const getProcessDataById = async (processId: number | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		const resp = await apiService.call("[CAS].[DEGetProcess]", parameters);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const getLastestProcesses = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	return await apiService.callV2("[CAS].[GetLastestNProcesses]", parameters);
};
export const searchProcesses = async (searchTerm: string) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [
		{ name: "SearchTerm", value: searchTerm },
		{ name: "MaxResults", value: 100 },
	];
	return await apiService.callV2("[CAS].[SearchProcesses]", parameters);
};
export const getChemistrySampleByHeatId = async (heatId: number | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "HeatId", value: heatId });
	try {
		const resp = await apiService.call(
			"[LAB].[GetChemistrySampleByHeatId]",
			parameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getGanttData = async (Start: Date | null, End: Date | null) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	if (!moment.isDate(Start) || isNaN(Start.getTime())) {
		Start = null;
	}
	if (!moment.isDate(End) || isNaN(End.getTime())) {
		End = null;
	}

	parameters.push({ name: "Start", value: Start ? Start.toISOString() : null });
	parameters.push({ name: "End", value: End ? End.toISOString() : null });

	const resp = await apiService.callV2("[SWV].[GetGanttData]", parameters);
	return resp;
};

export const getDelayById = async (delayId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "DelayId", value: delayId });

	return await apiService.callV2("[DEL].[GetDelay]", parameters);
};

//Delivery Ticket
export const getDeliveryTicket = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ProcessId", value: processId });

	const resp = await apiService.callV2("[SWV].[GetDeliveryTicket]", parameters);
	return resp;
};
export const getReadProductBatches = async (ProcessId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "ProcessId", value: ProcessId });

	const resp = await apiService.callV2(
		"[CAS].[ReadProductBatches]",
		parameters,
	);
	return resp;
};
export const getMoldSizes = async () => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	const resp = await apiService.callV2("[CAS].[GetMoldSizes]", parameters);
	return resp;
};

export const upsertProductBatch = async (
	productBatchId: number | null,
	processId: number,
	length: number,
	sectionId: number,
	pieces: number,
	weight: number | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push(
		{ name: "ProductBatchId", value: productBatchId },
		{ name: "ProcessId", value: processId },
		{ name: "Length", value: length },
		{ name: "SectionId", value: sectionId },
		{ name: "Pieces", value: pieces },
		{ name: "Weight", value: weight },
	);
	const resp = await apiService.callV2(
		"[CAS].[UpsertProductBatch]",
		parameters,
	);
	return resp;
};
export const GetProcessCatalogs = async () => {
	try {
		const apiService = new MESApiService();
		const resp = await apiService.call("[CAS].[DEGetCatalogs]", []);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const upsertProcessData = async (
	processId: number,
	field: string,
	value: any,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: field, value: value });
	parameters.push({ name: "IsProcessDataManual", value: true });

	try {
		return await apiService.callV2("[CAS].[UpdateProcessData]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const upsertPourback = async (
	PourbackId: number | null,
	HeatId: number,
	SourceProcessId: number | null,
	PourbackWeight: number | null,
	PourbackReasonId: number | null,
	SourceTime: Date,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "PourbackId", value: PourbackId });
	parameters.push({ name: "HeatId", value: HeatId });
	parameters.push({ name: "SourceProcessId", value: SourceProcessId });
	parameters.push({ name: "PourbackWeight", value: PourbackWeight });
	parameters.push({ name: "PourbackReasonId", value: PourbackReasonId });
	parameters.push({ name: "SourceTime", value: SourceTime });

	return await apiService.callV2("[MS].[UpsertPourback]", parameters);
};
export const updateTrimData = async (
	processId: number,
	field: string,
	value: any,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: field, value: value });
	parameters.push({ name: "IsTrimDataManual", value: true });

	try {
		return await apiService.callV2("[CAS].[UpdateTrimData]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const upsertStrandData = async (
	processId: number,
	strand: number,
	field: string,
	value: any,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: "Strand", value: strand });
	parameters.push({ name: field, value: value });
	parameters.push({ name: "IsStrandDataManual", value: true });

	return await apiService.callV2("[CAS].[UpdateStrandData]", parameters);
};
export const updateLabTechnician = async (
	HeatId: number,
	Technician: string | null,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "HeatId", value: HeatId });
	parameters.push({ name: "Technician", value: Technician });

	return await apiService.callV2("[LAB].[UpdateLabTechnician]", parameters);
};
export const upsertProbeData = async (
	probeId: number | null,
	processId: number,
	timestamp: Date,
	field: string,
	value: any,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProbeId", value: probeId });
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: "Timestamp", value: timestamp });
	if (field !== "Timestamp") parameters.push({ name: field, value: value });
	parameters.push({ name: "IsProbeManual", value: true });
	try {
		return await apiService.callV2("[CAS].[UpsertProbe]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const deleteProbe = async (probeId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProbeId", value: probeId });
	try {
		return await apiService.callV2("[CAS].[DeleteProbe]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
//Read PLC
export const ReadStrandDataFromPLC = async (
	processId: number,
	strand: number,
	forced: boolean,
) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: "Strand", value: strand });
	parameters.push({ name: "Force", value: forced });
	try {
		return await apiService.callV2("[CAS].[ReadStrandDataFromPLC]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const readProbesFromPLC = async (processId: number, forced: boolean) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	parameters.push({ name: "Force", value: forced });
	try {
		return await apiService.callV2("[CAS].[ReadProbesFromPLC]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const CalculateProcessData = async (processId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];
	parameters.push({ name: "ProcessId", value: processId });
	try {
		return await apiService.callV2("[CAS].[CalculateProcessData]", parameters);
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
//Caster Counters
export const getCountersByShift = async (shiftId: number, deleted: boolean) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "@ShiftId", value: shiftId });
	sqlParameters.push({ name: "@GetDeleted", value: deleted });
	try {
		const apiService = new MESApiService();
		const resp = await apiService.call(
			"[CAS].[GetCountersByShift]",
			sqlParameters,
		);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};

export const ReadTrimDataFromPLC = async ({
	processId,
}: {
	processId: number;
}) => {
	const api = new MESApiService();
	return await api.callV2("[CAS].[ReadTrimDataFromPLC]", [
		{
			name: "ProcessId",
			value: processId,
		},
	]);
};
