import { HeatNavigationProps } from "@/controls/heatNavigation/types/public-types";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import React from "react";
import { AutoComplete } from "../../autoComplete";

export const HeatNavigationV2: React.FunctionComponent<HeatNavigationProps> = ({
	heats,
	title = "Heat",
	defaultText = "Enter a Heat Number",
	onValueChanged,
	onTextChanged,
	onNextClicked,
	onPreviousClicked,
	textColor = "black",
	heatStatus = null,
}) => {
	const searchHeat = async (textSearch: string) => {
		if (onTextChanged) {
			onTextChanged(textSearch);
		}
	};

	const iconButtonStyle = {
		backgroundColor: "#3182ce",
		border: "none",
		padding: "12px",
		borderRadius: "50%",
		boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
		margin: "0 8px",
		color: "white",
		"&:hover": {
			backgroundColor: "#2c5282",
		},
	};

	return (
		<Grid
			item
			container
			alignItems="center"
			spacing={2}
			style={{ overflow: "hidden" }}
		>
			<Grid
				item
				xs={12}
				md={12}
				style={{ marginLeft: "15px", minWidth: 545, maxWidth: 545 }}
			>
				<div className="input-group">
					<div className="input-group-prepend">
						<span className="input-group-text">{title}</span>
						<IconButton onClick={onPreviousClicked} style={iconButtonStyle}>
							<ChevronLeft style={{ fontSize: "24px" }} />
						</IconButton>
					</div>
					<AutoComplete
						items={heats}
						defaultText={defaultText}
						noOptionsText={"No heats to show"}
						width={200}
						containerPadding={"0 0.5rem"}
						onTextSearch={searchHeat}
						onChangeValue={onValueChanged}
						color={textColor}
					/>
					<div className="input-group-append">
						<IconButton onClick={onNextClicked} style={iconButtonStyle}>
							<ChevronRight style={{ fontSize: "24px" }} />
						</IconButton>
					</div>
					{heatStatus && (
						<div
							style={{
								backgroundColor: heatStatus?.color || "grey",
								color: "#fff",
								borderRadius: "4px",
								padding: "10px",
								textAlign: "center",
								marginLeft: "10px",
								minWidth: "100px",
							}}
						>
							<Typography>{heatStatus?.name}</Typography>
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
};
