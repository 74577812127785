import { FilterParameters } from "./FilterParameters";

export const FILTER_INITIAL_VALUES: FilterParameters = {
	AssetId: null,
	SearchText: "",
	SearchShiftParameters: {
		StartShiftId: null,
		EndShiftId: null,
	},
};
