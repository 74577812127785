import { EafSummary } from "@/pages/shift-report/models/ShiftReportModels";
import { ColDef, ColGroupDef } from "ag-grid-community";

export const useTableData = (eafSummaryRows: EafSummary[]) => {
	const dataByAsset: Record<string, EafSummary> = eafSummaryRows.reduce(
		(acc, cur) => {
			acc[cur.AssetName] = cur;
			return acc;
		},
		{} as Record<string, EafSummary>,
	);

	const properties = [
		{ property: "Heats", field: "Heats" },
		{ property: "Scrap Tons", field: "ScrapTons" },
		{ property: "Energy", field: "Energy" },
		{ property: "KWH/Ton  [avg]", field: "KWHperTon" },
		{ property: "Liq Weight", field: "LiquidWeight" },
		{ property: "Yield", field: "Yield" },
		{ property: "Tap To Tap  [avg]", field: "TapToTap" },
		{ property: "Power On  [avg]", field: "PowerOn" },
		{ property: "Tons/Hour  [avg]", field: "TonsPerHour" },
		{ property: "Pourbacks", field: "Pourbacks" },
	];

	const rowData = properties.map((m) => ({
		Property: m.property,
		EAF1: dataByAsset["EAF 1"]
			? dataByAsset["EAF 1"][m.field as keyof EafSummary]
			: null,
		EAF2: dataByAsset["EAF 2"]
			? dataByAsset["EAF 2"][m.field as keyof EafSummary]
			: null,
		Total: dataByAsset["Total"]
			? dataByAsset["Total"][m.field as keyof EafSummary]
			: null,
	}));

	const createValueGetter =
		(field: keyof (typeof rowData)[0]) => (params: any) => {
			const value = params.data[field];
			if (value == null) return null;
			if (params.data.Property === "Yield") {
				return `${Number(value).toFixed(2)}%`;
			}
			if (Number.isInteger(value)) {
				return value;
			}
			return Number(value).toFixed(2);
		};

	const columns: (ColGroupDef | ColDef)[] = [
		{
			headerName: "EAF Summary",

			children: [
				{
					field: "Property",
					headerName: "",
					flex: 1,
					minWidth: 90,
					cellClass: "first-column-cell",
				},
				{
					field: "EAF1",
					headerName: "EAF 1",
					flex: 1,
					minWidth: 63,
					valueGetter: createValueGetter("EAF1"),
				},
				{
					field: "EAF2",
					headerName: "EAF 2",
					flex: 1,
					minWidth: 63,
					valueGetter: createValueGetter("EAF2"),
				},
				{
					field: "Total",
					headerName: "Total",
					flex: 1,
					minWidth: 55,
					valueGetter: createValueGetter("Total"),
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	return { columns, defaultColDef, rowData };
};
