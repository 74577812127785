import LogbookEntryIndex from "@/pages/logbook/logbook-entry";
import LogbookReportIndex from "@/pages/logbook/logbook-report";
import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";

export const LogbookRoutes = [
	<Route
		path="/logbook-report/*"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<LogbookReportIndex />
			</Suspense>
		}
	/>,
	<Route
		path="/logbook-entry/*"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<LogbookEntryIndex />
			</Suspense>
		}
	/>,
];
