import { QualitySummary } from "@/pages/shift-report/models/ShiftReportModels";
import { ColDef, ColGroupDef } from "ag-grid-community";

export const useTableData = (rows: QualitySummary) => {
	const rowData = [
		{
			Property: "Missed Heats",
			Value: rows?.MissedHeats ? rows?.MissedHeats : 0,
		},
		{ Property: "Missing C", Value: rows?.MissingC ? rows?.MissingC : 0 },
		{ Property: "Missing S", Value: rows?.MissingS ? rows?.MissingS : 0 },
		{ Property: "Missing P", Value: rows?.MissingP ? rows?.MissingP : 0 },
		{ Property: "Samples", Value: rows?.Samples ? rows?.Samples : 0 },
	];

	const columns: (ColGroupDef | ColDef)[] = [
		{
			headerName: "Quality Summary",
			children: [
				{
					field: "Property",
					headerName: "",
					flex: 1,
					minWidth: 150,
					cellClass: "first-column-cell",
				},
				{
					field: "Value",
					headerName: "Value",
					flex: 1,
					minWidth: 100,
					valueGetter: (params) => {
						const value = params.data.Value;
						if (value == null) return null;

						if (typeof value === "number" && Number.isInteger(value)) {
							return value;
						}
						if (typeof value === "number") {
							return value.toFixed(2);
						}
						return value;
					},
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	return { columns, defaultColDef, rowData };
};
