import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { upsertEAFProcessData } from "../../../../../eaf/eaf-processes/repositories/EAFRepository";
import { upsertHeat } from "../../../../../meltshop/heats/repositories/heatsRepository";
import { GradeSelector } from "../../../../../meltshop/month-report/components/selector/GradeSelector";
import { DeliveryTicket } from "../../../../models/DeliveryTicket";
import { updateLabTechnician } from "../../../../repositories/ProductionRepository";
import { useCastersContext } from "../../../CastersContext";
import { useSaveProcess } from "../../../hooks/useSaveProcess";
import { MoldSizeSelector } from "./selector/MoldSizeSelector";
import { useDeliveryTicketStyles } from "./styles/deliveryTicketStyles";

interface Props {
	deliveryTicket: DeliveryTicket | null;
	handleHeatInputChange: (field: string, value: any) => void;
	dateTimeFormatted: any;
	errors: Map<string, string>;
	setErrors: Dispatch<SetStateAction<Map<string, string>>>;
}

export const ProcessData = ({
	deliveryTicket,
	handleHeatInputChange,
	dateTimeFormatted,
	errors,
	setErrors,
}: Props) => {
	const { state, actions } = useCastersContext();
	const { saveProcessData } = useSaveProcess();

	const classes = useDeliveryTicketStyles();
	const [error, setError] = useState("");

	const [casterForeman, setCasterForeman] = useState<string | null>(
		deliveryTicket?.heatData.CasterForeman ?? null,
	);
	const [melter, setMelter] = useState<string | null>(
		deliveryTicket?.heatData.Melter ?? null,
	);
	const [labTech, setLabTech] = useState<string | null>(
		deliveryTicket?.heatData.LabTech ?? null,
	);
	const [sequence, setSequence] = useState<string | null>(
		deliveryTicket?.heatData.Sequence ?? null,
	);
	const handleChangeGrade = async (gradeIdSelected: number | null) => {
		handleHeatInputChange("GradeId", gradeIdSelected);
		if (gradeIdSelected === null) {
			setErrors((prevErrors) => {
				const newErrors = new Map(prevErrors);
				newErrors.set("GradeId", "Please select a grade");
				return newErrors;
			});
			return;
		} else {
			setErrors((prevErrors) => {
				const newErrors = new Map(prevErrors);
				newErrors.delete("GradeId");
				return newErrors;
			});
		}
		if (deliveryTicket) {
			const resp = await upsertHeat({
				GradeId: gradeIdSelected,
				HeatId: deliveryTicket.heatData.HeatId,
			});
			if (!resp.ok) setError(resp.message);
			else actions.setNotificationMessage("Grade was saved successfully");
		}
	};
	const handleChangeMoldSize = (moldSizeIdSelected: number | null) => {
		handleHeatInputChange("MoldSizeId", moldSizeIdSelected);
		saveProcessData("MoldSizeId", moldSizeIdSelected);
	};
	const handleCasterForemanChange = () => {
		handleHeatInputChange("CasterForeman", casterForeman);
		saveProcessData("Foreman", casterForeman);
	};

	const handleMelterChange = async () => {
		handleHeatInputChange("Melter", melter);
		if (deliveryTicket?.heatData.EAFProcessId) {
			const resp = await upsertEAFProcessData(
				deliveryTicket?.heatData.EAFProcessId,
				"Foreman",
				melter,
			);
			if (!resp.ok) setError(resp.message);
			else actions.setNotificationMessage("Melter was saved successfully");
		} else setError("EAF Process not provided");
	};
	const handleLabTechChange = async () => {
		handleHeatInputChange("LabTech", labTech);
		if (deliveryTicket?.heatData.HeatId) {
			const resp = await updateLabTechnician(
				deliveryTicket?.heatData.HeatId,
				labTech,
			);
			if (!resp.ok) setError(resp.message);
			else actions.setNotificationMessage("Lab Tech was saved successfully");
		} else setError("Heat not provided");
	};
	const handleSequenceChange = () => {
		handleHeatInputChange("Sequence", sequence);
		saveProcessData("Sequence", sequence);
	};
	return (
		<>
			<Grid item container xs={8} spacing={1} style={{ marginBottom: "30px" }}>
				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>HEAT No:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							value={deliveryTicket?.heatData.HeatName ?? ""}
							onChange={(e) =>
								handleHeatInputChange("HeatName", e.target.value)
							}
							fullWidth
							variant="outlined"
							size="small"
							InputProps={{
								readOnly: true,
							}}
							className={classes.disabledInput + " " + classes.input}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>GRADE:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<GradeSelector
							value={deliveryTicket?.heatData.GradeId ?? null}
							initialValue={{ GradeId: null, GradeName: "" }}
							onChange={(value) => {
								handleChangeGrade(value);
							}}
							className={classes.input}
							inputError={errors.get("GradeId")}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>MOLD SIZE:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<MoldSizeSelector
							value={deliveryTicket?.heatData.MoldSizeId ?? null}
							onChange={(value) => {
								handleChangeMoldSize(value);
							}}
							className={classes.input}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>
							CASTER FOREMAN:
						</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							value={casterForeman}
							onBlur={(e) => handleCasterForemanChange()}
							onChange={(e) => setCasterForeman(e.target.value)}
							fullWidth
							variant="outlined"
							size="small"
							className={classes.input}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>CAST DATE:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							type="date"
							value={dateTimeFormatted?.castDateFormatted ?? ""}
							onChange={(e) =>
								handleHeatInputChange("CastDate", e.target.value)
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								readOnly: true,
							}}
							fullWidth
							variant="outlined"
							size="small"
							className={classes.disabledInput + " " + classes.input}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>TAP TIME:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							type="time"
							value={dateTimeFormatted?.tapTime ?? ""}
							onChange={(e) => handleHeatInputChange("TapTime", e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								readOnly: true,
							}}
							fullWidth
							variant="outlined"
							size="small"
							className={classes.disabledInput + " " + classes.input}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>MELTER:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							value={melter}
							onChange={(e) => setMelter(e.target.value)}
							onBlur={(e) => {
								handleMelterChange();
							}}
							fullWidth
							variant="outlined"
							size="small"
							className={classes.input}
						/>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>LAB TECH:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							value={labTech}
							onChange={(e) => setLabTech(e.target.value)}
							onBlur={(e) => {
								handleLabTechChange();
							}}
							fullWidth
							variant="outlined"
							size="small"
							className={classes.input}
						/>
					</Grid>
				</Grid>
				<Grid item container xs={12}>
					<Grid item xs={6} alignContent="center">
						<Typography style={{ fontWeight: "bold" }}>SEQUENCE:</Typography>
					</Grid>
					<Grid item xs={6} alignContent="center">
						<TextField
							value={sequence}
							onChange={(e) => setSequence(e.target.value)}
							onBlur={(e) => {
								handleSequenceChange();
							}}
							fullWidth
							variant="outlined"
							size="small"
							className={classes.input}
						/>
					</Grid>
				</Grid>
			</Grid>
			<ErrorModal
				error={error || state.errorMessage}
				onHide={() => {
					if (error) setError("");
					if (state.errorMessage) actions.setErrorMessage("");
				}}
			/>
		</>
	);
};
