import { HeatShiftReport } from "@/pages/shift-report/models/ShiftReportModels";
import { useTablesStyles } from "@/pages/shift-report/styles/useTablesStyles";
import { Box, Paper } from "@material-ui/core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { useTableData } from "./hooks/useTableData";

type Props = {
	isLoading: boolean;
	heats: HeatShiftReport[];
};

export const HeatsTable = ({ isLoading, heats }: Props) => {
	const classes = useTablesStyles();

	const { columnData, defaultColDef } = useTableData({
		heats,
	});
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);

	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
	};
	useEffect(() => {
		if (gridAPI) {
			if (isLoading) gridAPI.showLoadingOverlay();
			else gridAPI.hideOverlay();
		}
	}, [gridAPI, isLoading]);

	return (
		<Paper className={classes.heatsTable} elevation={0}>
			<Box className={`ag-theme-balham ${classes.smAgGridBox}`}>
				<AgGridReact
					defaultColDef={defaultColDef}
					rowHeight={35}
					rowData={heats}
					columnDefs={columnData}
					rowSelection="single"
					overlayLoadingTemplate={"Loading..."}
					suppressContextMenu={true}
					getRowId={(params) => params.data.Heat}
					onGridReady={onGridReady}
				/>
			</Box>
		</Paper>
	);
};
