import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-community";
import { useEffect, useState } from "react";

export const useTableData = () => {
	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	const columns: ColDef[] = [
		{
			field: "AssetName",
			headerName: "Asset",
			sortable: true,
			flex: 1,
			minWidth: 60,
		},
		{
			field: "Title",
			headerName: "Title",
			sortable: false,
			flex: 1,
			minWidth: 160,
		},
		{
			field: "Content",
			headerName: "Content",
			sortable: false,
			flex: 5,
			minWidth: 95,
			wrapText: true,
			autoHeight: true,
		},
		{
			field: "Author",
			headerName: "Author",
			sortable: false,
			flex: 1,
			minWidth: 95,
		},
	];

	return {
		columns,
		defaultColDef,
	};
};
