import {
	ErrorModal,
	ShiftDayNavigatorControl,
	getMomentTz,
	useShiftNavigator,
} from "@dexteel/mesf-core";
import {
	Box,
	CircularProgress,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-balham.min.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LazyBackDropLoading, LazyLoading } from "../../controls/LazyLoading";
import { NotificationSnackBar } from "../../controls/snackbars/notification-snackbar";
import { useResize } from "../../utils/useResize";
import {
	getCasterChemistries,
	getCasterProductions,
	getGanttData,
} from "../caster/repositories/ProductionRepository";
import { useCasterDashboardContext } from "./CasterDashboardContext";
import { CasterProductionTable } from "./components/CasterProduction/CasterProductionTable";
import { GanttSection } from "./components/GanttSection/GanttSection";
import { MissedChemistriesTable } from "./components/MissedChemistries/MissedChemistriesTable";

import { getProcessesByShift } from "../eaf/eaf-processes/repositories/EAFRepository";
import { EAFProcessesReadOnlyTable } from "./components/EAFProcesses/EAFProcessesTable";
import { useGanttUpdater } from "./hooks/useGanttUpdater";

type Props = {};
export const MeltshopDashboardPage = (props: Props) => {
	const { shiftId: shiftIdParam } = useParams();
	const { state, actions } = useCasterDashboardContext();
	const { width } = useResize();
	const colWidth = (width * 0.9) / 12;
	const handleContainerContextMenu = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();
	};

	const { shiftInfo, getShiftDataFromAPI } = useShiftNavigator();

	const { assets, updateGantt } = useGanttUpdater(shiftInfo);

	const refreshData = async () => {
		if (shiftInfo === null || shiftInfo.CurrentShiftId === 0) {
			actions.setCasterProductions([]);
			actions.setCasterChemistries([]);
			actions.setProcessGanttChart([]);
			return;
		}

		actions.setShowLoading(true);
		actions.setIsLoadingProductionGrid(true);
		actions.setIsLoadingChemistryGrid(true);
		actions.setIsLoadingBackground(true);

		const promises = [
			// EAF Processes
			getProcessesByShift(shiftInfo.CurrentShiftId, false),
			// Caster Productions
			getCasterProductions({
				startDate: null,
				endDate: null,
				shiftId: shiftInfo.CurrentShiftId,
				gradeId: 0,
				billetSizeId: 0,
			}),
			// Caster Chemistries
			getCasterChemistries({
				startDate: null,
				endDate: null,
				shiftId: shiftInfo.CurrentShiftId,
				gradeId: 0,
				billetSizeId: 0,
				includeExternalHeats: true,
			}),
			// Gantt Data
			getGanttData(shiftInfo.CurrentStart, shiftInfo.CurrentEnd),
		];

		const [eafResp, productionsResp, chemistriesResp, ganttResp] =
			await Promise.all(promises);

		if (eafResp.ok) {
			actions.setEAFProcesses(eafResp.data?.tables[0]?.rows || []);
		} else {
			actions.setErrorMessage(eafResp.message);
		}

		if (productionsResp.ok) {
			actions.setCasterProductions(productionsResp.data?.tables[0]?.rows || []);
		} else {
			actions.setErrorMessage(productionsResp.message);
		}

		if (chemistriesResp.ok) {
			actions.setCasterChemistries(chemistriesResp.data?.tables[0]?.rows || []);
		} else {
			actions.setErrorMessage(chemistriesResp.message);
		}

		if (ganttResp.ok) {
			updateGantt(ganttResp.data);
		} else {
			actions.setErrorMessage(ganttResp.message);
		}

		actions.setIsLoadingProductionGrid(false);
		actions.setIsLoadingChemistryGrid(false);
		actions.setIsLoadingBackground(false);
		actions.setShowLoading(false);
	};

	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {
		if (shiftInfo && isInitialized) {
			refreshData();
		}
	}, [shiftInfo]);

	useEffect(() => {
		(async () => {
			if (!isInitialized) {
				if (shiftIdParam) {
					setIsInitialized(true);
					await getShiftDataFromAPI(null, Number(shiftIdParam));
				} else {
					setIsInitialized(true);
					await getShiftDataFromAPI(null, 0);
				}
			}
		})();
	}, []);

	if (state.isLoadingPage) return <LazyLoading />;

	return (
		<div onContextMenu={handleContainerContextMenu}>
			<Paper
				elevation={1}
				style={{
					width: "100%",
					padding: "15px 20px 10px",
					borderRadius: "0.75rem",
				}}
			>
				<Grid
					container
					justifyContent="flex-start"
					alignItems="flex-start"
					direction={"column"}
				>
					<Grid item xs={12}>
						<Typography variant="h5" style={{ fontWeight: 600 }}>
							Melt Shop Dashboard
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<ShiftDayNavigatorControl preventInit />
					</Grid>
					<ErrorModal
						error={state.errorMessage}
						onHide={() => actions.setErrorMessage("")}
					/>
					<NotificationSnackBar
						message={state.notificationMessage}
						onHide={() => actions.setNotificationMessage("")}
					/>
				</Grid>

				<Paper>
					<Box padding={3} style={{ position: "relative" }}>
						<Typography variant="h4">Gantt</Typography>
						{shiftInfo && assets.length > 0 && (
							<GanttSection
								key={`shift-${shiftInfo.CurrentShiftId}`}
								columnWidth={colWidth}
								assets={assets}
								showArrowTriangle={false}
								startCalendarDate={shiftInfo?.CurrentStart}
								endCalendarDate={shiftInfo?.CurrentEnd}
								rowHeight={150}
								barFill={50}
								lineSectionFill={40}
								lineFill={40}
								refreshData={refreshData}
							/>
						)}
						{state.showLoading && (
							<CircularProgress
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
								}}
							/>
						)}
					</Box>
				</Paper>
				<Paper>
					<Box padding={3}>
						<Typography variant="h4">EAF Production</Typography>
						<EAFProcessesReadOnlyTable
							loadingGrid={state.isLoadingPage}
							rows={state.eafProcesses}
						/>
					</Box>
				</Paper>
				<Paper>
					<Box padding={3}>
						<Typography variant="h4">Caster Production</Typography>
						<div className="row">
							<CasterProductionTable />
						</div>
					</Box>
				</Paper>
				<Paper>
					<Box padding={3}>
						<Typography variant="h4">Missed Chemistries</Typography>
						<div className="row">
							<MissedChemistriesTable />
						</div>
					</Box>
				</Paper>
			</Paper>
		</div>
	);
};
