import { ErrorModal, getMomentTz, useUserContext } from "@dexteel/mesf-core";
import {
	Button,
	Divider,
	Grid,
	Paper,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { get } from "lodash-es";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../controls/snackbars/notification-snackbar";
import { DelaysReportTable } from "./components/DelaysReportTable";
import { Filters } from "./components/filters/Filters";
import { useDelaysReportContext } from "./context/DelaysReportContext";
import {
	getDelayAreas,
	getDelays,
} from "./repositories/DelaysReportRepository";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: "15px 20px 10px",
		borderRadius: "0.75rem",
		height: "91vh",
		display: "flex",
		flexDirection: "column",
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	filterContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-end",
		marginBottom: theme.spacing(2),
	},
	buttonGroup: {
		display: "flex",
		gap: theme.spacing(2),
	},
	button: {
		whiteSpace: "nowrap",
	},
}));

const moment = getMomentTz();

export const DelaysReportPage: React.FC = () => {
	const classes = useStyles();
	const { state, actions } = useDelaysReportContext();
	const {
		state: { defaultAreaId },
	} = useUserContext();

	const [error, setError] = useState("");
	const [filterStartDate, setFilterStartDate] = useState<Date>(() => {
		const startDate = moment()
			.subtract(1, "day")
			.set({ hour: 0, minute: 0, second: 0 })
			.toDate();
		return new Date(startDate);
	});
	const [filterEndDate, setFilterEndDate] = useState<Date>(() => {
		const endDate = moment().set({ hour: 23, minute: 59, second: 59 }).toDate();
		return new Date(endDate);
	});
	const [filterCrew, setFilterCrew] = useState<string | null>("All");
	const [filterShift, setFilterShift] = useState<string | null>("All");

	const [selectedDelayArea, setSelectedDelayArea] = useState<number | null>(
		null,
	);
	const [assetChangedManually, setAssetChangedManually] = useState(false);
	const [delayAreas, setDelayAreas] = useState<
		Array<{ AssetId: number; AssetName: string }>
	>([]);
	const [includeMicrodelays, setIncludeMicrodelays] = useState<boolean>(false);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const gridRef = useRef<AgGridReact>(null);

	const fetchDelayAreas = async () => {
		const resp = await getDelayAreas();
		if (resp.ok && resp.data.tables.length > 0) {
			const areas = resp.data.tables[0].rows;
			setDelayAreas(areas);
		} else {
			setError("An error occurred while fetching delay areas.");
		}
	};

	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getDelays(
			filterStartDate,
			filterEndDate,
			selectedDelayArea as number,
			filterShift as string,
			filterCrew as string,
			includeMicrodelays,
		);

		if (resp.ok) {
			const data = get(resp, "data.tables[0].rows", []);
			actions.setDelays(data);
		} else setError(resp.message);
		actions.setIsLoadingGrid(false);
	};

	const handleFilterChange = (startDate: Date, endDate: Date) => {
		const start = moment
			.utc(startDate)
			.set({ hour: 0, minute: 0, second: 0 })
			.toDate();
		const end = moment(endDate)
			.set({ hour: 23, minute: 59, second: 59 })
			.toDate();
		setFilterStartDate(start);
		setFilterEndDate(end);
	};

	const handleDelayAreaChange = (assetId: number | null) => {
		setSelectedDelayArea(assetId);
		setAssetChangedManually(true);
	};

	const handleIncludeMicrodelaysChange = (include: boolean) => {
		setIncludeMicrodelays(include);
	};

	const handleSearch = () => {
		getDataFromAPI();
	};

	const handleExportToExcel = () => {
		if (gridRef.current && gridRef.current.api) {
			const params = {
				skipHeader: false,
				skipFooters: true,
				skipGroups: true,
				fileName: "delays_report.xlsx",
			};
			gridRef.current.api.exportDataAsExcel(params);
		} else {
			setError("Unable to export data. Please try again.");
		}
	};

	useEffect(() => {
		const assetIdParam = searchParams.get("assetId");
		if (assetIdParam) {
			setSelectedDelayArea(Number(assetIdParam));
		} else if (!assetChangedManually) {
			if (defaultAreaId && defaultAreaId !== 10) {
				setSelectedDelayArea(defaultAreaId as number);
			} else {
				setSelectedDelayArea(null);
			}
		}
	}, [searchParams, defaultAreaId]);

	useEffect(() => {
		const params = new URLSearchParams(searchParams);
		if (selectedDelayArea !== null) {
			params.set("assetId", selectedDelayArea.toString());
		} else {
			params.delete("assetId");
		}
		navigate({ search: params.toString() }, { replace: true });
	}, [selectedDelayArea]);

	useEffect(() => {
		if (defaultAreaId && defaultAreaId !== 10 && !assetChangedManually) {
			setSelectedDelayArea(defaultAreaId as number);
		}
	}, [defaultAreaId, assetChangedManually]);

	useEffect(() => {
		getDataFromAPI();
	}, [selectedDelayArea]);

	useEffect(() => {
		fetchDelayAreas();
		getDataFromAPI();
	}, []);

	if (!state.isLoadingPage) {
		return (
			<>
				<Paper elevation={1} className={classes.root}>
					<LazyBackDropLoading open={state.isLoadingBackground} />
					<Grid container justifyContent="center" alignItems="center">
						<Grid item xs={12}>
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
							>
								<Typography
									variant="h5"
									className={classes.header}
									style={{ fontWeight: 600 }}
								>
									Delays Report
								</Typography>
							</Grid>

							<Grid item md={12} className={classes.filterContainer}>
								<Grid item xs={12} md={8}>
									<Filters
										onFilterChange={handleFilterChange}
										delayAreas={delayAreas}
										selectedDelayArea={selectedDelayArea}
										onDelayAreaChange={handleDelayAreaChange}
										initialStartDate={filterStartDate}
										initialEndDate={filterEndDate}
										onFilterCrewChange={setFilterCrew}
										filterCrew={filterCrew as string}
										includeMicrodelays={includeMicrodelays}
										onIncludeMicrodelaysChange={handleIncludeMicrodelaysChange}
										filterShift={filterShift}
										onFilterShiftChange={setFilterShift}
									/>
								</Grid>
								<div className={classes.buttonGroup}>
									<Button
										variant="contained"
										color="primary"
										onClick={handleSearch}
										className={classes.button}
									>
										Search
									</Button>
									<Button
										variant="contained"
										color="primary"
										onClick={handleExportToExcel}
										disabled={!state.delays || state.delays.length === 0}
										className={classes.button}
									>
										Export to Excel
									</Button>
								</div>
							</Grid>
							<Divider style={{ marginBottom: 10 }} />
							<NotificationSnackBar
								message={state.notificationMessage}
								onHide={() => actions.setNotificationMessage("")}
							/>
							<Grid item xs={12}>
								<DelaysReportTable
									loadingGrid={state.isLoadingGrid}
									rows={state.delays || []}
									refreshData={getDataFromAPI}
									gridRef={gridRef}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<ErrorModal error={error} onHide={() => setError("")} />
			<LazyLoading />
		</div>
	);
};
