import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Logbook } from "./components/Logbook";
import { useSearchEntries } from "./hooks/useSearchEntries";

const useStyles = makeStyles((theme) => ({
	root: {
		"& h3": {
			display: "inline-block",
			marginTop: 0,
			paddingBotton: 0,
			paddingLeft: 20,
			border: "none",
			userSelect: "none",
		},
		"& .content-wrapper": {
			border: "none",
		},
		" & .rag-grey": {
			backgroundColor: "#E0E0E0 !important",
		},
	},
	cursorPointer: {
		cursor: "pointer",
	},
	btnSearch: {
		[theme.breakpoints.up("md")]: {
			marginLeft: "20px !important",
		},
	},
}));

export default function EntriesBrowser() {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>("");

	const searchEntries = useSearchEntries({ setIsLoading, setError });

	return (
		<div style={{ display: "flex" }}>
			<Grid container className={classes.root} style={{ padding: 20 }}>
				<Paper
					elevation={1}
					style={{
						width: "100%",
						padding: "15px 20px 30px",
						borderRadius: "0.75rem",
					}}
				>
					<>
						<Grid item md={12} xs={12}>
							<Typography
								variant="h5"
								style={{
									marginLeft: "10px",
									fontWeight: 600,
									userSelect: "none",
								}}
							>
								LOGBOOK ENTRY
							</Typography>
						</Grid>
						<Logbook isLoading={isLoading} searchEntries={searchEntries} />
					</>
				</Paper>
				<ErrorModal error={error} onHide={() => setError("")} />
			</Grid>
		</div>
	);
}
