import { LinkCellRenderer } from "@/controls/ag-grid/components/ButtonCellRenderer";
import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-community";

export const useTableData = () => {
	const columns: ColDef[] = [
		{
			headerName: "HeatName",
			field: "HeatNameValue",
			minWidth: 100,
			maxWidth: 100,
			cellRenderer: LinkCellRenderer,
			cellRendererParams: { linkProperty: "HeatNameLink" },
		},
		{
			headerName: "Grade",
			field: "Grade",
			minWidth: 150,
			maxWidth: 150,
		},
		{
			headerName: "Sample",
			field: "Sample",
			minWidth: 80,
			maxWidth: 80,
		},
		{
			headerName: "Elements",
			field: "Elements",
			flex: 2,
		},
	];

	const defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
		suppressMenu: true,
		autoHeight: true,
		wrapText: true,
	};

	return { columns, defaultColDef };
};
