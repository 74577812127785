import { DelayByShift } from "@/pages/delays/delays-manager/models/DelayByShift";
import { ShiftReportDelays } from "@/pages/shift-report/models/ShiftReportModels";
import { useTablesStyles } from "@/pages/shift-report/styles/useTablesStyles";
import {
	Box,
	Card,
	CardContent,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import { GridApi, GridReadyEvent, RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import { useTableData } from "./hooks/useTableData";
interface Props {
	rows: ShiftReportDelays[];
	isLoading: boolean;
}

export const DelaysTable = ({ rows, isLoading }: Props) => {
	const classes = useTablesStyles();
	const { columns, defaultColDef } = useTableData(rows);
	const [gridAPI, setGridAPI] = useState<GridApi | null>(null);
	const colors: { [assetName: string]: string } = {
		"EAF 1": "#dae8fc",
		"EAF 2": "#d5e8d4",
		Caster: "#f8cfcc",
	};

	const getRowClass = (params: RowClassParams) => {
		if (!params.data.DelayCodeName || !params.data.DelayCategoryName) {
			return classes.unclassifiedDelayRow;
		}
		return "";
	};
	const onGridReady = (params: GridReadyEvent) => {
		setGridAPI(params.api);
	};
	useEffect(() => {
		if (gridAPI) {
			if (isLoading) gridAPI.showLoadingOverlay();
			else gridAPI.hideOverlay();
		}
	}, [gridAPI, isLoading]);

	const assetsToShow = ["EAF 1", "EAF 2", "Caster"];
	const delaysByAsset = rows.reduce(
		(acc: Record<string, ShiftReportDelays[]>, current) => {
			const assetName = current.AssetName || "Unknown Asset";
			if (!acc[assetName]) {
				acc[assetName] = [];
			}
			acc[assetName].push(current);
			return acc;
		},
		{},
	);
	return (
		<Paper className={classes.root} elevation={0}>
			{assetsToShow.map((assetName) => {
				const assetRows = delaysByAsset[assetName] || [];
				return (
					<div key={assetName}>
						<Grid item xs={12} md={12}>
							<Card
								className={classes.assetCard}
								style={{ backgroundColor: colors[assetName] }}
								variant="outlined"
							>
								<CardContent>
									<Typography variant="h5">{assetName}</Typography>
								</CardContent>
							</Card>
						</Grid>
						<Box className={`ag-theme-balham ${classes.EAFAgGridBox}`}>
							<AgGridReact
								rowSelection="single"
								suppressRowClickSelection={true}
								pagination={false}
								rowHeight={40}
								headerHeight={35}
								singleClickEdit={false}
								tooltipShowDelay={0}
								tooltipHideDelay={2000}
								rowData={assetRows}
								columnDefs={columns}
								defaultColDef={defaultColDef}
								domLayout="normal"
								onGridReady={onGridReady}
								getRowClass={getRowClass}
							/>
						</Box>
					</div>
				);
			})}
		</Paper>
	);
};
