import { ReactNode, createContext, useContext } from "react";
import { useComplexState } from "use-complex-state";
import { ShiftReportReducer } from "../reducers/ShiftReportReducer";

const ShiftReportContext = createContext({
	state: ShiftReportReducer.getInitialState(),
	actions: ShiftReportReducer.actions,
});

export const useShiftReportContext = () => useContext(ShiftReportContext);

export const ShiftReportProvider = ({ children }: { children: ReactNode }) => {
	const [state, actions] = useComplexState({
		initialState: ShiftReportReducer.getInitialState(),
		reducers: ShiftReportReducer.caseReducers,
	});

	return (
		<ShiftReportContext.Provider value={{ state, actions }}>
			{children}
		</ShiftReportContext.Provider>
	);
};
