import { LinkCellRenderer } from "@/controls/ag-grid/components/ButtonCellRenderer";
import { HeatShiftReport } from "@/pages/shift-report/models/ShiftReportModels";
import {
	dxtToLocalServerTime,
	useUTLSettingsContext,
} from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-community";
import { get } from "lodash-es";

type Props = {
	heats: HeatShiftReport[];
};

export const useTableData = ({ heats }: Props) => {
	const { state } = useUTLSettingsContext();

	const MAX_TTT = get(state, "maxTTT", 180);
	const MAX_POWER_ON = get(state, "maxPowerOn", 140);
	const MAX_DURATION = get(state, "maxDuration", 120);
	const MAX_TAP_TEMP = get(state, "maxTapTemp", 3900);
	const MAX_TRIM_TEMP = get(state, "maxTrimTemp", 3300);
	const MIN_SUPER_HEAT = get(state, "minSuperHeat", 2259);

	const columnData: ColDef[] = [
		{
			headerName: "Heat",
			field: "Heat",
			flex: 1,
			minWidth: 100,
		},
		{
			headerName: "Grade",
			field: "Grade",
			minWidth: 65,
		},
		{
			headerName: "Ladle",
			field: "Ladle",
			minWidth: 62,
		},
		{
			headerName: "EAF Tap",
			field: "EAFTap",
			minWidth: 79,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data.EAFTap}`, "HH:mm"),
			cellRenderer: (params: any) => {
				if (params.data.HeatEAFDELink)
					return <LinkCellRenderer {...params} linkProperty="HeatEAFDELink" />;
				else return <span style={{ color: "black" }}>{params.value}</span>;
			},
		},
		{
			headerName: "TTT",
			field: "TTT",
			minWidth: 65,
			valueGetter: (params) =>
				params.data.TTT ? params.data.TTT.toString() : "",
			cellClassRules: {
				"max-limit": (params) => {
					return Number(params.value) > MAX_TTT; //3hs in minutes
				},
			},
		},
		{
			headerName: "Power On",
			field: "PowerOn",
			minWidth: 90,
			cellClassRules: {
				"max-limit": (params) => {
					return Number(params.value) > MAX_POWER_ON; // in minutes
				},
			},
		},
		{
			headerName: "Ladle Open",
			field: "LadleOpen",
			minWidth: 110,
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data.LadleOpen}`, "HH:mm"),
			cellRenderer: (params: any) => {
				if (params.data.HeatCASDELink)
					return <LinkCellRenderer {...params} linkProperty="HeatCASDELink" />;
				else return <span style={{ color: "black" }}>{params.value}</span>;
			},
		},
		{
			headerName: "Section",
			field: "Sections",
			minWidth: 120,
		},
		{
			headerName: "Length",
			field: "Length",
			minWidth: 120,
		},
		{
			headerName: "Sequence",
			field: "Sequence",
			minWidth: 90,
		},
		{
			headerName: "Duration",
			field: "Duration",
			minWidth: 90,
			valueGetter: (params) =>
				params.data.Duration ? params.data.Duration.toFixed(2) : "",
			cellClassRules: {
				"max-limit": (params) => {
					return Number(params.value) > MAX_DURATION; //in minutes
				},
			},
		},
		{
			headerName: "Tap Temp",
			field: "TapTemp",
			minWidth: 89,
			cellClassRules: {
				"max-limit": (params) => {
					return Number(params.value) > MAX_TAP_TEMP;
				},
			},
		},
		{
			headerName: "Trim Temp",
			field: "TrimTemp",
			minWidth: 95,
			cellClassRules: {
				"max-limit": (params) => {
					return Number(params.value) > MAX_TRIM_TEMP;
				},
			},
		},
		{
			headerName: "Last Superheat",
			field: "LastSuperheat",
			minWidth: 127,
			cellClassRules: {
				"max-limit": (params) => {
					return params.value !== null && Number(params.value) < MIN_SUPER_HEAT;
				},
			},
		},
		{
			headerName: "Tons",
			field: "Tons",
			minWidth: 60,
			valueGetter: (params) =>
				params.data.Tons ? params.data.Tons.toFixed(1) : "",
		},
		{
			headerName: "Scrap",
			field: "Scrap",
			minWidth: 80,
			valueGetter: (params) =>
				params.data.Scrap ? params.data.Scrap.toFixed(1) : "",
		},
		{
			headerName: "Missed Elements",
			field: "MissedElements",
			minWidth: 150,
		},
	];
	const defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
	};

	return { columnData, defaultColDef };
};
