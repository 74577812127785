import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import {
	Box,
	Button,
	FormControl,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import { isNil } from "lodash-es";
import { useNavigate } from "react-router-dom";
import { AssetTitle } from "./components/AssetTitle";
import { GradeSelector } from "./components/GradeSelector";
import { LadleLifeTrimTempCheckbox } from "./components/LadleLifeCheckbox";
import { LadleSelector } from "./components/LadleSelector";
import { Notifications } from "./components/Notifications";
import { ProcessNavigator } from "./components/ProcessNavigator";
import { RefreshButtons } from "./components/RefreshButtons";
import { DurationFieldDE } from "./components/form-fields/common/DurationFieldDE";
import DurationInput from "./components/form-fields/common/DurationInput";
import { NumberTextFieldDE } from "./components/form-fields/common/NumberTextFieldDE";
import { ProcessDataDateTimeFieldDE } from "./components/form-fields/process-data/ProcessDataDateTimeFieldDE";
import { ProcessDateTimeFieldDE } from "./components/form-fields/process/ProcessDateTimeFieldDE";
import { TableCaveIn } from "./components/tables/TableCaveIn";
import { TableForemanEtc } from "./components/tables/TableForemanEtc";
import { useEAFDETablesStyles } from "./components/tables/styles/useEAFDETablesStyles";
import { TableAdditions } from "./components/tables/table-additions/TableAdditions";
import { TableCharges } from "./components/tables/table-charges/TableCharges";
import { TableHeatings } from "./components/tables/table-heatings/TableHeatings";
import { TablePourbacks } from "./components/tables/table-pourbacks/TablePourbacks";
import { TableProbes } from "./components/tables/table-probes/TableProbes";
import { TrimTable } from "./components/tables/table-trim/TrimTable";
import { useDataEntryContext } from "./context/reducer.provider";
import { useTimerContext } from "./context/timer.provider";
import { useEAFDataEntryActions } from "./hooks/useEAFDataEntryActions";
import { stringTimeToMinutes } from "./utils/stringTimeToMinutes";

function decimalToTime(decimalMinutes: number | undefined) {
	const minutes = Math.floor(decimalMinutes ?? 0);
	const seconds = Math.round(((decimalMinutes ?? 0) - minutes) * 60);
	const formattedMinutes = String(minutes).padStart(2, "0");
	const formattedSeconds = String(seconds).padStart(2, "0");
	return `${formattedMinutes}:${formattedSeconds}`;
}
const moment = getMomentTz();
export const EafDataEntryPage = () => {
	const navigate = useNavigate();
	const { pause, resume } = useTimerContext();
	const {
		state: { deProcess },
		actions: { setProcess },
	} = useDataEntryContext();
	const { saveHeat, saveProcessData } = useEAFDataEntryActions();
	const process = deProcess?.process;

	const classes = useEAFDETablesStyles();
	return (
		<>
			<Box padding={1} style={{ backgroundColor: "#eaebec" }}>
				<Grid container spacing={1} direction={"column"}>
					<Grid item>
						<Typography variant={"h5"}>EAF Data Entry</Typography>
					</Grid>

					<Grid item>
						<Paper elevation={0}>
							<Box padding={1}>
								<Grid
									item
									container
									justifyContent={"space-between"}
									alignItems={"flex-start"}
									spacing={3}
								>
									<Grid container item direction="column" xs={12} md={2}>
										<Grid item xs={12} md={12}>
											<ProcessNavigator />
										</Grid>
										{process?.AssetName && process?.AssetId ? (
											<Grid item xs={12} md={12}>
												<AssetTitle
													assetName={process!.AssetName}
													assetId={process.AssetId}
													heatStatus={process.HeatStatusCode}
												/>
											</Grid>
										) : null}
									</Grid>
									<Grid
										key={`first-${process?.ProcessId}`}
										item
										xs={12}
										sm={6}
										md={2}
									>
										<Grid container direction={"column"} spacing={2}>
											<Grid item style={{ marginBottom: -4 }}>
												<TextField
													fullWidth
													label={"Shift"}
													variant={"outlined"}
													disabled
													value={`${dxtToLocalServerTime(
														process?.ProductionDate,
														"MM/dd/yy",
													)} ${process?.Shift} - ${process?.Crew}`}
												/>
											</Grid>
											<Grid item>
												<ProcessDateTimeFieldDE field={"Start"} />
											</Grid>
											{/* <Grid item>
												<ProcessDataDateTimeFieldDE
													field={"StartTap"}
													disabled={isNil(process?.End)}
													error={
														moment(process?.End).isBefore(
															moment(process?.StartTap),
														) ||
														moment(process?.StartTap).isBefore(
															moment(process?.Start),
														)
															? "Start Tap must be between Start and End"
															: ""
													}
												/>
											</Grid> */}
											<Grid item>
												<ProcessDateTimeFieldDE field={"End"} />
											</Grid>
										</Grid>
									</Grid>
									<Grid
										key={`sec-${process?.ProcessId}`}
										item
										xs={12}
										sm={6}
										md={2}
									>
										<Grid container direction={"column"} spacing={1}>
											<Grid item>
												<NumberTextFieldDE
													label={"Steel Weight [tons]"}
													value={
														process?.SteelWeight
															? parseFloat(process.SteelWeight.toFixed(1))
															: null
													}
													disabled={process?.HeatId ? false : true}
													onChange={(e) => {
														setProcess({
															...process,
															SteelWeight: e,
														});
													}}
													onBlur={() => {
														saveHeat("SteelWeight", process?.SteelWeight).then(
															() => {},
														);
													}}
												/>
											</Grid>
											<Grid item>
												<LadleSelector
													disabled={process?.HeatId ? false : true}
													value={process?.LadleId || null}
													onChange={(value) => {
														setProcess({
															...process,
															LadleId: value!,
														});
													}}
												/>
											</Grid>
											<Grid item>
												<LadleLifeTrimTempCheckbox
													ladleLife={{
														HeatsOnLadle: process?.HeatsOnLadle || null,
														ContactTimeOnLadle:
															process?.ContactTimeOnLadle || null,
													}}
													value={process?.ForceHeatsOnLadle || null}
													onChange={(value) => {
														saveHeat("ForceHeatsOnLadle", value);
													}}
													disabled={
														process?.HeatId || process?.LadleId ? false : true
													}
												/>
											</Grid>
											<Grid item>
												<GradeSelector
													disabled={process?.HeatId ? false : true}
													value={process?.GradeId || null}
													onChange={(value) => {
														setProcess({
															...process,
															GradeId: value,
														});
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										key={`third-${process?.ProcessId}`}
										item
										xs={12}
										sm={6}
										md={2}
									>
										<Grid item container direction={"column"} spacing={1}>
											<Grid item>
												<DurationFieldDE
													label={"Tap To Tap [hh:mm:ss]"}
													value={process?.TapToTap ? process?.TapToTap : null}
													unit={"minutes"}
													disabled
													onChange={(value) => {
														setProcess({
															...process,
															TapTime: Number(value),
														});
													}}
												/>
											</Grid>
											<Grid item>
												<DurationInput
													label={"Power On [hh:mm]"}
													disabled={isNil(process?.End)}
													initMinutes={process?.PowerOn || 0}
													onFocusHandler={pause}
													onBlurHandler={(e) => {
														const value = stringTimeToMinutes(
															e.target.value || "00:00",
														);
														saveProcessData({
															processId: process?.ProcessId!,
															field: "PowerOn",
															value: value || 0,
														});
														resume();
													}}
													onTimeChange={(value) => {
														setProcess({
															...process,
															PowerOn: Number(value),
														});
													}}
												/>
											</Grid>
											<Grid item>
												<TextField
													fullWidth
													disabled
													label={"Power Off [hh:mm]"}
													variant={"outlined"}
													value={
														process?.PowerOff
															? decimalToTime(process?.PowerOff / 60)
															: 0
													}
													onChange={(e) => {
														setProcess({
															...process,
															PowerOn: Number(e.target.value),
														});
													}}
												/>
											</Grid>
											<Grid item>
												<NumberTextFieldDE
													label={"Kwh / tons"}
													value={
														process?.KWHPerTon
															? parseFloat(process.KWHPerTon.toFixed(1))
															: null
													}
													onChange={(value) => {
														setProcess({
															...process,
															KWHPerTon: Number(value),
														});
													}}
													onBlur={() => {
														saveProcessData({
															processId: process?.ProcessId!,
															field: "KWHPerTon",
															value: process?.KWHPerTon
																? process?.KWHPerTon
																: 0,
														});
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										key={`fourth-${process?.ProcessId}`}
										item
										xs={12}
										sm={12}
										md={4}
										style={{ marginTop: 5 }}
									>
										<Grid item container direction={"column"} spacing={2}>
											<Grid item xs={12} sm={12} md={6}>
												<RefreshButtons />
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Button
													fullWidth
													variant={"outlined"}
													size="large"
													onClick={() =>
														navigate(`/eaf-processes/${process?.ShiftId}`)
													}
												>
													Processes
												</Button>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Button
													fullWidth
													variant={"outlined"}
													size="large"
													disabled
												>
													Heat Report
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Paper>
					</Grid>
					<Grid item container spacing={1}>
						<Grid item xs={12} md={4}>
							<Grid container direction={"column"} spacing={1}>
								<Grid item xs={12} md={12}>
									<TableProbes />
								</Grid>
								<Grid item xs={12} md={12}>
									<TableAdditions />
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={4}>
							<Grid container direction={"column"} spacing={1}>
								<Grid item xs={12} md={12}>
									<Paper className={classes.root} elevation={0}>
										<Box
											padding={1}
											className={`ag-theme-balham ${classes.agGridBox}`}
										>
											<Grid
												container
												spacing={1}
												style={{ justifyContent: "center" }}
											>
												<Grid item xs={12} md={6}>
													<TableForemanEtc />
												</Grid>
												<Grid item xs={12} md={6}>
													<TableCaveIn />
												</Grid>
											</Grid>
										</Box>
									</Paper>
								</Grid>
								<Grid item xs={12} md={12}>
									<TableHeatings />
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4}>
							<Grid container direction={"column"} spacing={1}>
								<Grid item xs={12} md={12}>
									<TableCharges />
								</Grid>
								<Grid item xs={12} md={12}>
									<TablePourbacks />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						<Paper>
							<Box padding={1}>
								<FormControl>
									<TextareaAutosize
										className={classes.outlinedTextarea}
										placeholder="Notes"
										minRows={4}
										style={{ width: "100%" }}
										value={process?.Comments ?? ""}
										onChange={(e) => {
											setProcess({
												...process,
												Comments: e.target.value,
											});
										}}
										onBlur={() => {
											saveProcessData({
												processId: process?.ProcessId!,
												field: "Comments",
												value: process?.Comments ?? "",
											}).then(() => {});
										}}
										onFocus={() => pause()}
									/>
								</FormControl>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</Box>

			<Notifications />
		</>
	);
};

export default EafDataEntryPage;
