import { CasterChemistry } from "@/pages/caster/models/CasterChemistry";
import { DelayByShift } from "@/pages/delays/delays-manager/models/DelayByShift";
import { Entry } from "@/pages/logbook/models/Entry";
import { createSlice } from "@reduxjs/toolkit";
import {
	CasSummary,
	EafSummary,
	HeatShiftReport,
	PrdSummary,
	QualitySummary,
	ShiftReportDelays,
} from "../models/ShiftReportModels";

const ShiftReportInitialState: {
	isDataLoading: boolean;
	eafSummary: EafSummary[];
	casSummary: CasSummary[];
	qualitySummary: QualitySummary;
	heats: HeatShiftReport[];
	delays: ShiftReportDelays[];
	prdSummary: PrdSummary[];
	logbook: Entry[];
	missedChemistries: CasterChemistry[];
} = {
	isDataLoading: false,
	eafSummary: [],
	casSummary: [],
	qualitySummary: {
		MissedHeats: 0,
		MissingC: 0,
		MissingS: 0,
		MissingP: 0,
		Samples: 0,
	},
	heats: [],
	prdSummary: [],
	logbook: [],
	delays: [],
	missedChemistries: [],
};

export const ShiftReportReducer = createSlice({
	name: "__",
	initialState: ShiftReportInitialState,
	reducers: {
		setIsDataLoading(state, { payload }) {
			state.isDataLoading = payload;
		},
		setEafSummary(state, { payload }) {
			state.eafSummary = payload;
		},
		setCasSummary(state, { payload }) {
			state.casSummary = payload;
		},
		setHeats(state, { payload }) {
			state.heats = payload;
		},
		setPrdSummary(state, { payload }) {
			state.prdSummary = payload;
		},
		setDelays(state, { payload }) {
			state.delays = payload;
		},
		setLogbook(state, { payload }) {
			state.logbook = payload;
		},
		setMissedChemistries(state, { payload }) {
			state.missedChemistries = payload;
		},
		setQualitySummary(state, { payload }) {
			state.qualitySummary = payload;
		},
	},
});
