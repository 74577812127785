import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MarkdownIt from "markdown-it";
import React, { useMemo, useEffect, useState } from "react";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import { dxtToLocalServerTime } from "@dexteel/mesf-core";

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			height: "100%",
			width: "100%",
			overflow: "hidden",
			display: "flex",
			flexDirection: "column",
			"& .MuiInput-underline:before": {
				borderBottom: "none",
			},
			"& .MuiInput-underline:after": {
				borderBottom: "none",
			},
			"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
				borderBottom: "none",
			},
		},
		content: {
			flexGrow: 1,
			display: "flex",
			flexDirection: "column",
			overflow: "hidden",
		},
		fixedTextField: {
			backgroundColor: theme.palette.background.paper,
			borderTop: `0px solid ${theme.palette.divider}`,
			paddingLeft: 10,
		},
		editorWrapper: {
			height: "100%",
			"& .rc-md-editor": {
				height: "100%",
			},
			"& .rc-md-navigation": {
				backgroundColor: theme.palette.background.paper,
			},
			"& .rc-md-editor, & .section-container": {
				height: "100%",
			},
		},
	}),
);

type Props = {
	content: string | null;
	setContentSelectedInTable: (content: string) => void;
	isEditing: boolean;
	isNewEntry: boolean;
	UpdateTimestamp: Date;
	UpdatedBy: string;
};

export const ContentInput: React.FC<Props> = ({
	content,
	setContentSelectedInTable,
	isEditing,
	UpdateTimestamp,
	UpdatedBy,
	isNewEntry,
}) => {
	const classes = useStyles();
	const mdParser = useMemo(() => new MarkdownIt(), []);
	const [key, setKey] = useState(0);

	useEffect(() => {
		setKey((prevKey) => prevKey + 1);
	}, [isEditing]);

	const handleChange = ({ text }: { text: string }) => {
		setContentSelectedInTable(text);
	};

	return (
		<div className={classes.root}>
			<Grid item xs className={classes.content}>
				<div className={classes.editorWrapper}>
					<MdEditor
						key={key}
						style={{ height: "100%", width: "100%" }}
						value={content || ""}
						renderHTML={(text) => mdParser.render(text || "")}
						onChange={handleChange}
						view={{
							menu: false,
							md: isEditing ? true : false,
							html: !isEditing,
						}}
						autoFocus={!isNewEntry}
					/>
				</div>
			</Grid>

			{UpdatedBy?.length > 0 && (
				<div className={classes.fixedTextField}>
					<TextField
						variant="standard"
						disabled={true}
						value={`This entry was updated on ${
							dxtToLocalServerTime(
								UpdateTimestamp,
								"MM/dd/yy hh:mm a",
							) as string
						} by ${UpdatedBy}`}
						fullWidth
						margin="none"
						autoComplete="off"
						InputProps={{
							disableUnderline: true,
						}}
					/>
				</div>
			)}
		</div>
	);
};
