import React from "react";
import { CasterDashboardProvider } from "../meltshop-dashboard/CasterDashboardContext";
import { ShiftReportPage } from "./ShiftReportPage";
import { ShiftReportProvider } from "./context/ShiftReportContext";

const ShiftReport = () => {
	return (
		<ShiftReportProvider>
			<CasterDashboardProvider>
				<ShiftReportPage />
			</CasterDashboardProvider>
		</ShiftReportProvider>
	);
};

export default ShiftReport;
