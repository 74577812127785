import { Button } from "@material-ui/core";
import React from "react";

interface StatusCellRendererProps {
	value: boolean;
	type: "check" | "sent" | "ack" | "force";
	api: any;
	node: any;
	column: any;
	data?: any;
}

const StatusCellRenderer = (props: StatusCellRendererProps) => {
	if (props.type === "force") {
		return (
			<Button
				variant="contained"
				color="primary"
				size="small"
				style={{ minWidth: "60px" }}
			>
				SEND
			</Button>
		);
	}

	const getStyles = () => {
		const baseStyles = {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "30px",
			height: "24px",
			borderRadius: "4px",
			fontWeight: "bold",
			cursor: "pointer",
		};

		if (props.value) {
			return {
				...baseStyles,
				backgroundColor: "#4caf50",
				color: "white",
			};
		}

		if (props.type === "check") {
			return {
				...baseStyles,
				backgroundColor: "#f44336",
				color: "white",
			};
		}

		return {
			...baseStyles,
			backgroundColor: "#9e9e9e",
			color: "white",
		};
	};

	return (
		<div
			style={getStyles()}
			onClick={() => {
				const newValue = !props.value;
				const field = props.column.colId;
				props.node.setDataValue(field, newValue);
			}}
			title={props.value ? "Yes" : "No"}
		>
			{props.value ? "Y" : "N"}
		</div>
	);
};

export default StatusCellRenderer;
