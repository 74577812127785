import {
	default as React,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { useComplexState } from "use-complex-state";
import { ItemOption } from "../../../controls/autoComplete";
import { ResultSearchHeatName } from "../models/ResultSearchHeatName";
import { searchHeatNames } from "../repositories/HeatRepository";
import { HeatReportReducer } from "./HeatReportReducer";

const HeatReportContext = createContext({
	state: HeatReportReducer.getInitialState(),
	actions: HeatReportReducer.actions,
});

export const useHeatReportContext = () => useContext(HeatReportContext);

export const CasterHeatReportProvider = () => {
	const [state, actions] = useComplexState({
		initialState: HeatReportReducer.getInitialState(),
		reducers: HeatReportReducer.caseReducers,
	});
	const urlParameters = useParams();
	const [heatNameParam] = useState(
		urlParameters.heatName === undefined ? "" : urlParameters.heatName,
	);
	const updateHistory = (heatName: string) => {
		const paths = location.pathname.split("/");
		window.history.replaceState(
			null,
			"",
			`/${paths[1]}` + `/${paths[2]}` + `/${heatName}`,
		);
	};
	const location = useLocation();
	useEffect(() => {
		(async () => {
			if (state.searchHeatName !== null) {
				actions.setIsLoadingBackground(true);

				const response = await searchHeatNames(
					state.searchHeatName,
					state.showExternalsHeats,
				);
				if (response.ok) {
					if (true) actions.setHeatResults(response.data.tables[0]?.rows);
					else actions.setHeatResults([]);
				} else actions.setErrorMessage(response.message);
				actions.setIsLoadingBackground(false);
			}
		})();
	}, [state.searchHeatName, state.showExternalsHeats]);
	useEffect(() => {
		let shouldChange: boolean;
		shouldChange =
			state.selectedHeat == null ||
			state.selectedHeat?.HeatName !== heatNameParam;
		if (shouldChange) {
			actions.setSearchHeatName(heatNameParam);
		}
	}, [heatNameParam]);
	useEffect(() => {
		const itemList: ItemOption[] = [];
		state.heatResults.forEach((heat) => {
			const itemOption: ItemOption = {
				id: heat.HeatId.toString(),
				name: heat.HeatName,
			};
			itemList.push(itemOption);
		});
		actions.setHeatItems(itemList);
	}, [state.heatResults]);
	useEffect(() => {
		let heat: ResultSearchHeatName | null = null;
		if (state.selectedOption !== null) {
			const id = false ? 0 : state.selectedOption.id;
			const searchedHeat = state.heatResults.find(
				(x) => x.HeatId === Number(id),
			);
			heat = searchedHeat === undefined ? null : searchedHeat;
		}
		updateHistory(heat === null ? "" : heat.HeatName);
		actions.setSelectedHeat(heat);
	}, [state.selectedOption]);

	return (
		<HeatReportContext.Provider value={{ state, actions }}>
			<Outlet />
		</HeatReportContext.Provider>
	);
};
