import ShiftReport from "@/pages/shift-report";
import { Suspense } from "react";
import { Route } from "react-router-dom";
import { LazyLoading } from "../../controls/LazyLoading";

export const ShiftReportRoutes = [
	<Route
		path="/shift-report/*"
		element={
			<Suspense fallback={<LazyLoading></LazyLoading>}>
				<ShiftReport />
			</Suspense>
		}
	/>,
];
