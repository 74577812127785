import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getHeats = async () => {
	const apiService = new MESApiService();

	try {
		const resp = await apiService.call("[MS].[GetHeats]", []);
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getHeat = async (HeatId: number) => {
	const apiService = new MESApiService();
	const parameters: spParameter[] = [];

	parameters.push({ name: "HeatId", value: HeatId });
	try {
		const resp = await apiService.call("[SWV].[GetHeat]", parameters);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const getLastHeat = async () => {
	const apiService = new MESApiService();
	try {
		const resp = await apiService.call("[MS].[GetLastHeat]", []);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
export const searchHeatNames = async (
	searchText: string,
	IncludeIsExternal?: boolean,
) => {
	try {
		const sqlParameters = [];
		sqlParameters.push({ name: "@SearchText", value: searchText.trim() });
		sqlParameters.push({
			name: "@ShowExternalHeats",
			value: IncludeIsExternal,
		});
		const apiService = new MESApiService();
		const resp = await apiService.call("[MS].[SearchHeatNames]", sqlParameters);
		if (resp.errorMessage !== null)
			return { ok: false, message: resp.errorMessage };
		return {
			ok: true,
			data: resp,
		};
	} catch (e: any) {
		return { ok: false, message: e.toString() };
	}
};
