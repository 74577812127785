import { Button } from "@material-ui/core";
import React from "react";

interface SendButtonCellRendererProps {
	clicked: Function;
	data: any;
}

const SendButtonCellRenderer = (props: SendButtonCellRendererProps) => {
	return (
		<Button
			variant="contained"
			color="primary"
			size="small"
			style={{ minWidth: "60px" }}
			onClick={() => props.clicked(props.data)}
			disabled={props.data?.Sent}
		>
			SEND
		</Button>
	);
};

export default SendButtonCellRenderer;
