import { LazyLoading } from "@/controls/LazyLoading";
import { dxtToLocalServerTime, dxtToUTC } from "@dexteel/mesf-core";
import { Button, Grid, IconButton, Paper, makeStyles } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import {
	CellKeyDownEvent,
	ColDef,
	EditableCallbackParams,
	GetContextMenuItemsParams,
	GridApi,
	MenuItemDef,
	RowDoubleClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useLogbookContext } from "../context/logbookContext";
import { ENTRY_INITIAL_VALUES } from "../models/EntryInitialValue";
import { RemoveEntryDialog } from "./dialog/remove-entry-dialog";
import { Filters } from "./filters/filters";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		height: "58vh",
		"& h3": {
			display: "inline-block",
			marginTop: 0,
			paddingBotton: 0,
			paddingLeft: 20,
			border: "none",
			userSelect: "none",
		},
		"& .content-wrapper": {
			border: "none",
		},
		"& .ag-header-label-icon": {
			display: "none",
		},
		"& .ag-floating-filter-button": {
			padding: 0,
			marginLeft: "0px",
		},
		"& .ag-icon-menu": {
			display: "none",
		},
		"& .ag-root-wrapper": {
			borderRadius: 3,
		},
		"& .ag-header-cell": {
			padding: "0 15px !important",
			textAlign: "center !important",
			fontSize: 10,
		},
		"& .ag-header-cell-label": {
			justifyContent: "center",
		},
		"& .ag-cell": {
			padding: "0px !important",
			textAlign: "center !important",
			fontSize: 12,
			lineBreak: "auto",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			border: "0 !important",
			wordBreak: "break-word",
		},
		"& .ag-comments": {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center",
		},
		"& .ag-selection-checkbox": {
			marginLeft: "10px !important",
		},
		"& .ag-paging-panel": {
			height: "60px !important",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "0 16px",
		},
		"& .ag-paging-row-summary-panel": {
			color: "#FFFF !important",
		},
	},
	gridContainer: {
		width: "60%",
		height: "100%",
		padding: "0px 9px 10px",
		borderRadius: "0.75rem",
		marginRight: 30,
		marginLeft: 10,
		marginTop: 10,
		display: "flex",
		flexDirection: "column",
	},
	agGridContainer: {
		position: "relative",
		flex: 1,
	},
	btnAdd: {
		position: "absolute",
		padding: 0,
		bottom: 16,
		left: 14,
		cursor: "pointer",
	},
	paginationContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		height: "60px",
		padding: "0 16px",
		borderTop: "1px solid #BDC3C7",
	},
	addButton: {
		display: "flex",
		alignItems: "center",
	},
	filtersContainer: {
		height: "auto",
		marginBottom: 20,
	},
	outletContainer: {
		width: "60%",
		height: "100%",
	},
}));

export const Logbook = ({
	searchEntries,
	isLoading,
}: { searchEntries: Function; isLoading: boolean }) => {
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const classes = useStyles();
	const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

	const params = useParams();
	const navigate = useNavigate();
	const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
	const [showRemoveEntryDialog, setShowRemoveEntryDialog] = useState(false);
	const { EntryId } = useParams();

	const {
		state: { EntriesList, EntrySelectedInTable, loadingTable, exportExcel },
		actions: {
			setEntrySelectedInTable,
			setEntryTitleToDelete,
			setEntryIdToDelete,
			setIsEditing,
			setExportExcel,
		},
	} = useLogbookContext();
	const loadingOverlayComponent = useMemo<any>(() => {
		return LazyLoading;
	}, []);

	const defaultColDef = useMemo<ColDef>(() => {
		return {
			sortable: true,
			wrapText: true,
			autoHeight: true,
			wrapHeaderText: true,
			flex: 1,
		};
	}, []);

	const icons = useMemo(() => {
		return {
			filter: '<i class="fas fa-bars"></i>',
		};
	}, []);

	const onActionsClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		params: EditableCallbackParams,
	) => {
		(params.api as any).contextMenuFactory.showMenu(
			params.node,
			params.column,
			undefined,
			e,
		);
	};

	const rowClicked = (event: RowDoubleClickedEvent) => {
		setEntrySelectedInTable(event?.data);
		setIsEditing(false);
		navigate(`${event.data?.EntryId}${location.search}`);
	};

	const onDobleClicked = (event: RowDoubleClickedEvent) => {
		setEntrySelectedInTable(event?.data);
		setIsEditing(true);
		navigate(`${event.data?.EntryId}${location.search}`);
	};

	const addEntry = () => {
		setEntrySelectedInTable({
			...ENTRY_INITIAL_VALUES,
			CreateTimestamp: new Date(),
			Shift: EntrySelectedInTable.Shift,
			Crew: EntrySelectedInTable.Crew,
			Author: EntrySelectedInTable.Author,
		});
		navigate(`new${location.search}`);
	};

	const onCellKeyDown = useCallback(
		(event: CellKeyDownEvent) => {
			const { api, node, event: keyEvent } = event;
			if (!keyEvent) return;
			const key = (keyEvent as KeyboardEvent).key;

			if (key === "ArrowUp" || key === "ArrowDown") {
				keyEvent.preventDefault();
				const currentIndex = node.rowIndex !== null ? node.rowIndex : -1;
				const nextRowIndex =
					key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;
				const nextNode = api.getDisplayedRowAtIndex(nextRowIndex);

				if (nextNode) {
					nextNode.setSelected(true);
					api.ensureIndexVisible(nextRowIndex);
					setEntrySelectedInTable(nextNode.data);
					navigate(`${nextNode.data?.EntryId}${location.search}`);
				}
			}
		},
		[navigate, setEntrySelectedInTable],
	);

	const getLogbookContextMenuItems = useCallback(
		(params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
			const data: any = params.node?.data;
			const result: (string | MenuItemDef)[] = [];
			if (data) {
				result.push({
					name: "<strong>Edit Entry</strong>",
					action: () => {
						setEntrySelectedInTable(data);
						navigate(`${data?.EntryId}${location.search}`);
						setIsEditing(true);
					},
					icon: '<i class="fas fa-edit"></i>',
				});
			}

			result.push({
				name: "Add Entry",
				action: () => {
					setEntrySelectedInTable({
						...ENTRY_INITIAL_VALUES,
						CreateTimestamp: new Date(),
						Shift: EntrySelectedInTable.Shift,
						Crew: EntrySelectedInTable.Crew,
						Author: EntrySelectedInTable.Author,
					});
					setIsEditing(true);
					navigate(`new${location.search}`);
				},
				icon: '<i class="fas fa-plus"></i>',
			});

			if (data) {
				{
					result.push({
						name: "Remove ",
						action: () => {
							setEntryIdToDelete(data.EntryId);
							setEntryTitleToDelete(data.Title);
							setShowRemoveEntryDialog(true);
						},
						icon: '<i class="fas fa-trash"></i>',
					});
				}
			}
			if (!data) {
				result.push({
					name: "Export to Excel",
					action: customExcelExport,
					icon: '<i class="fas fa-file-excel"></i>',
				});
			}

			return result;
		},
		[gridApi],
	);

	useEffect(() => {
		const newColumns: ColDef[] = [];
		newColumns.push({
			field: "CreateTimestamp",
			headerName: "Created",
			minWidth: 80,
			maxWidth: 80,
			flex: 1,
			valueGetter: (params) => {
				return params?.data?.CreateTimestamp
					? new Date(params.data.CreateTimestamp)
					: null;
			},
			valueFormatter: (params) => {
				return params.value
					? dxtToLocalServerTime(params.value, "MM/dd/yy")
					: "";
			},
			filterParams: {
				comparator: (filterLocalDateAtMidnight: Date, cellValue: any) => {
					const cellDate = new Date(cellValue);
					cellDate.setHours(0, 0, 0, 0);

					const filterDate = new Date(filterLocalDateAtMidnight);
					filterDate.setHours(0, 0, 0, 0);

					if (cellDate.getTime() === filterDate.getTime()) {
						return 0;
					}
					if (cellDate < filterDate) {
						return -1;
					}
					if (cellDate > filterDate) {
						return 1;
					}
				},
				browserDatePicker: true,
				minValidYear: 2000,
				maxValidYear: 2050,
				inRangeInclusive: true,
				includeBlanksInEquals: false,
				includeBlanksInLessThan: false,
				includeBlanksInGreaterThan: false,
			},
		}),
			newColumns.push({
				field: "AssetName",
				headerName: "Asset",
				sortable: true,
				flex: 1,
				minWidth: 60,
			}),
			newColumns.push({
				field: "Title",
				headerName: "Title",
				sortable: false,
				flex: 7,
				minWidth: 160,
			}),
			newColumns.push({
				field: "Author",
				headerName: "Created By",
				sortable: false,
				flex: 1,
				minWidth: 95,
			}),
			newColumns.push({
				field: "Content",
				headerName: "Content",
				hide: true,
				suppressColumnsToolPanel: true,
				suppressFiltersToolPanel: true,
				suppressMenu: true,
				suppressSizeToFit: true,
				valueGetter: (params) => params.data.Content,
			});
		newColumns.push({
			headerName: "",
			flex: 1,
			minWidth: 65,
			maxWidth: 65,
			cellClass: "ag-columns",
			wrapText: false,
			autoHeight: false,
			cellRenderer: (params: EditableCallbackParams) => {
				return (
					<Grid
						container
						style={{
							height: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Grid
							item
							xs={12}
							md={12}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Grid
								container
								spacing={1}
								style={{ justifyContent: "flex-end", minWidth: 30 }}
							>
								<Grid item xs={12} md={12}>
									<Button fullWidth onClick={(e) => onActionsClick(e, params)}>
										<FormatListBulletedSharpIcon
											style={{ height: "auto" }}
											color="action"
										/>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				);
			},
		}),
			setColumnDefs(newColumns);
	}, [EntryId]);

	useEffect(() => {
		if (isLoading || loadingTable) {
			gridApi?.showLoadingOverlay();
		} else gridApi?.hideOverlay();
	}, [isLoading, gridApi, loadingTable]);

	const customExcelExport = useCallback(() => {
		const params = {
			fileName: "Logbook_Export.xlsx",
			columnKeys: [
				"CreateTimestamp",
				"Shift",
				"Crew",
				"Title",
				"CreatedBy",
				"Author",
				"Content",
			],
			processCellCallback: (params: any) => {
				if (params.column.colId === "CreateTimestamp") {
					return dxtToUTC(params.value, "MM/dd/yy");
				}
				return params.value;
			},
		};
		gridApi?.exportDataAsExcel(params);
	}, [gridApi]);

	useEffect(() => {
		if (exportExcel) {
			customExcelExport();
			setExportExcel(false);
		}
	}, [exportExcel, customExcelExport, setExportExcel]);

	return (
		<Grid container>
			<Grid item container>
				<Filters searchEntries={searchEntries} />
			</Grid>
			<div className={classes.root}>
				<Paper elevation={1} className={classes.gridContainer}>
					<div className={classes.agGridContainer} style={{ padding: 5 }}>
						<div style={gridStyle} className="ag-theme-balham">
							<AgGridReact
								rowData={EntriesList}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								rowHeight={38}
								headerHeight={42}
								loadingOverlayComponent={loadingOverlayComponent}
								animateRows={true}
								getContextMenuItems={(e: any) => getLogbookContextMenuItems(e)}
								icons={icons}
								pagination={true}
								onRowClicked={rowClicked}
								onRowDoubleClicked={onDobleClicked}
								rowSelection="single"
								onGridReady={(params) => setGridApi(params.api)}
								onCellKeyDown={onCellKeyDown}
								getRowId={(params) => params.data.EntryId}
							/>
						</div>
						<IconButton
							onClick={() => {
								addEntry();
								setIsEditing(true);
							}}
							aria-label="add"
							color="primary"
							className={classes.btnAdd}
						>
							<AddBoxIcon
								style={{ width: 35, height: 35, cursor: "pointer" }}
							/>
						</IconButton>
					</div>
					<RemoveEntryDialog
						show={showRemoveEntryDialog}
						onHide={(shouldUpdate) => {
							if (shouldUpdate) {
								searchEntries();
							}
							setShowRemoveEntryDialog(false);
						}}
					/>
				</Paper>
				<div className={classes.outletContainer}>
					<Outlet key={`entry-${params?.EntryId}`} />
				</div>
			</div>
		</Grid>
	);
};
