import { PrdSummary } from "@/pages/shift-report/models/ShiftReportModels";
import { ColDef, ColGroupDef } from "ag-grid-community";

interface UseTableDataResult {
	columns: (ColGroupDef | ColDef)[];
	defaultColDef: ColDef;
	rowData: PrdSummary[];
}

export const useTableData = (
	prdSummaryRows: PrdSummary[],
): UseTableDataResult => {
	const columns: ColDef[] = [
		{
			field: "Grade",
			headerName: "Grade",
			flex: 1,
			minWidth: 90,
			cellClass: "first-column-cell",
		},
		{
			field: "Section",
			headerName: "Section",
			flex: 1,
			minWidth: 90,
			cellClass: "first-column-cell",
		},
		{
			field: "Heats",
			headerName: "Heats",
			flex: 1,
			minWidth: 60,
			valueGetter: (params) => {
				const value = params.data.Heats;
				return Number.isInteger(value) ? value : value?.toFixed(2);
			},
		},
		{
			field: "Billets",
			headerName: "Billets",
			flex: 1,
			minWidth: 60,
			valueGetter: (params) => {
				const value = params.data.Billets;
				return Number.isInteger(value) ? value : value?.toFixed(2);
			},
		},
		{
			field: "Tons",
			headerName: "Tons",
			flex: 1,
			minWidth: 60,
			valueGetter: (params) => {
				const value = params.data.Tons;
				return Number.isInteger(value) ? value : value?.toFixed(2);
			},
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	const rowData = prdSummaryRows;

	return { columns, defaultColDef, rowData };
};
