import { ManualIconCellRenderer } from "@/controls/ag-grid/components/ButtonCellRenderer";
import { CategoryTag } from "@/pages/delays/delays-manager/components/CategoryTag";
import { dxtToLocalServerTime } from "@dexteel/mesf-core";
import { ColDef } from "ag-grid-enterprise";
import moment from "moment";

type Props = {};

const applyCommonColumnSettings = (column: ColDef): ColDef => {
	return {
		...column,
		sortable: true,
		wrapText: true,
		autoHeight: true,
		wrapHeaderText: true,
	};
};

export const useTableData = ({}: Props) => {
	const columns: ColDef[] = [
		applyCommonColumnSettings({
			field: "AssetName",
			headerName: "Asset",
			minWidth: 80,
			editable: false,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Start",
			headerName: "Start",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.Start}`, "HH:mm:ss") || "",
			minWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "End",
			headerName: "End",
			valueGetter: (params) =>
				dxtToLocalServerTime(`${params.data?.End}`, "HH:mm:ss") || "",
			minWidth: 80,
			flex: 1,
		}),
		applyCommonColumnSettings({
			field: "Duration",
			headerName: "Duration",
			valueFormatter: (params) => {
				if (params.value) {
					const duration = moment.duration(params.value, "seconds");
					const minutes = Math.floor(duration.asMinutes());
					const seconds = duration.seconds();
					return `${minutes}m ${seconds}s`;
				}
				return "";
			},
			minWidth: 80,
			flex: 1,
		}),
		{
			field: "DelayCategory",
			headerName: "Category",
			valueGetter: (params) => {
				const delayCategoryName = params.data?.DelayCategoryName;
				const delayCategoryColor = params.data?.DelayCategoryColor;
				return { name: delayCategoryName, color: delayCategoryColor };
			},
			cellRendererFramework: CategoryTag,
			minWidth: 150,
			maxWidth: 150,
			editable: false,
			cellStyle: { justifyContent: "" },
			flex: 1,
		},
		applyCommonColumnSettings({
			field: "DelayCodeName",
			headerName: "Code",
			valueGetter: (params) => params.data?.DelayCodeName || "No Classified",
			minWidth: 250,
			editable: false,
			flex: 1,
			cellStyle: { justifyContent: "center" },
		}),
		applyCommonColumnSettings({
			field: "Comments",
			headerName: "Comments",
			minWidth: 100,
			flex: 2,
			cellStyle: { justifyContent: "flex-start" },
		}),
		applyCommonColumnSettings({
			field: "IsManual",
			headerName: "",
			type: ["nonSorteable", "nonFilterable", "centerAligned"],
			cellRenderer: ManualIconCellRenderer,
			cellClass: "ag-button-cell",
			flex: 1,
			maxWidth: 60,
		}),
	];

	const defaultColDef: ColDef = {
		flex: 1,
		resizable: true,
	};

	return { columns, defaultColDef };
};
