import { MESApiService, spParameter } from "@dexteel/mesf-core";

export const getEAFSummary = async (shiftId: number) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });

	const apiService = new MESApiService();
	const resp = await apiService.callV2("[EAF].[GetShiftReport]", sqlParameters);

	return resp;
};

export const getCASSummary = async (shiftId: number) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });

	const apiService = new MESApiService();
	const resp = await apiService.callV2("[CAS].[GetShiftReport]", sqlParameters);

	return resp;
};
export const getQualitySummary = async (shiftId: number) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });

	const apiService = new MESApiService();
	const resp = await apiService.callV2("[LAB].[GetShiftReport]", sqlParameters);

	return resp;
};

export const getHeatsForShiftReport = async (shiftId: number) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });

	const apiService = new MESApiService();
	const resp = await apiService.callV2(
		"[SWV].[GetHeatsShiftReport]",
		sqlParameters,
	);

	return resp;
};
export const getProductionSummary = async (shiftId: number) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });

	const apiService = new MESApiService();
	const resp = await apiService.callV2(
		"[SWV].[GetPRDShiftReport]",
		sqlParameters,
	);

	return resp;
};

export const getTopFiveDelays = async (
	shiftId: number,
	delayAreaAssetId: number | null,
	includeMicrodelays: boolean = false,
) => {
	const apiService = new MESApiService();
	const sqlParameters: spParameter[] = [];

	sqlParameters.push({ name: "ShiftId", value: shiftId });
	sqlParameters.push({ name: "DelayAreaAssetId", value: delayAreaAssetId });
	sqlParameters.push({ name: "IncludeMicrodelays", value: includeMicrodelays });
	const resp = await apiService.callV2(
		"[DEL].[GetShiftReportDelays]",
		sqlParameters,
	);

	return resp;
};

export const getLogbookShiftReport = async (shiftId: number) => {
	const sqlParameters = [];
	sqlParameters.push({ name: "ShiftId", value: shiftId });

	const apiService = new MESApiService();
	const resp = await apiService.callV2(
		"[SWV].[GetHeatsShiftReport]",
		sqlParameters,
	);

	return resp;
};
