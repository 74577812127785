import {
	CasSummary,
	EafSummary,
} from "@/pages/shift-report/models/ShiftReportModels";
import { ColDef, ColGroupDef } from "ag-grid-community";

export const useTableData = (casSummaryRows: CasSummary[]) => {
	const dataByStrand: Record<string, CasSummary> = casSummaryRows.reduce(
		(acc, cur) => {
			acc[cur.Strand] = cur;
			return acc;
		},
		{} as Record<string, CasSummary>,
	);

	const properties = [
		{ property: "Heats", field: "Heats" },
		{ property: "Liq Weight", field: "LiqWeight" },
		{ property: "Product Tons", field: "ProductTons" },
		{ property: "Tons/Hour [avg]", field: "TonsPerHour" },
		{ property: "Run Time", field: "RunTime" },
		{ property: "Lost Strand", field: "LostStrand" },
		{ property: "Scrap Billets", field: "ScrapBillets" },
	];

	const rowData = properties.map((m) => ({
		Property: m.property,
		s1: dataByStrand["1"]
			? dataByStrand["1"][m.field as keyof CasSummary]
			: null,
		s2: dataByStrand["2"]
			? dataByStrand["2"][m.field as keyof CasSummary]
			: null,
		s3: dataByStrand["3"]
			? dataByStrand["3"][m.field as keyof CasSummary]
			: null,
		Total: dataByStrand["Total"]
			? dataByStrand["Total"][m.field as keyof CasSummary]
			: null,
	}));
	const createValueGetter =
		(field: keyof (typeof rowData)[0]) => (params: any) => {
			const value = params.data[field];
			if (value == null) return null;
			if (params.data.Property === "Run Time") {
				return `${Number(value).toFixed(2)}%`;
			}
			if (Number.isInteger(value)) {
				return value;
			}
			return Number(value).toFixed(2);
		};

	const commonCellClassRules = {
		"null-value-cell": (params: any) =>
			params.data.Property == "Heats" ||
			params.data.Property == "Liq Weight" ||
			params.data.Property == "Lost Strand",
	};
	const columns: (ColGroupDef | ColDef)[] = [
		{
			headerName: "CAS Summary",

			children: [
				{
					field: "Property",
					headerName: "",
					flex: 1,
					minWidth: 90,
					cellClass: "first-column-cell",
				},
				{
					field: "1",
					headerName: "S1",
					flex: 1,
					minWidth: 63,
					valueGetter: createValueGetter("s1"),
					cellClassRules: commonCellClassRules,
				},
				{
					field: "2",
					headerName: "S2",
					flex: 1,
					minWidth: 63,
					valueGetter: createValueGetter("s2"),
					cellClassRules: commonCellClassRules,
				},
				{
					field: "3",
					headerName: "S3",
					flex: 1,
					minWidth: 63,
					valueGetter: createValueGetter("s3"),
					cellClassRules: commonCellClassRules,
				},
				{
					field: "Total",
					headerName: "Total",
					flex: 1,
					minWidth: 55,
					valueGetter: createValueGetter("Total"),
				},
			],
		},
	];

	const defaultColDef: ColDef = {
		filter: false,
		floatingFilter: false,
		sortable: false,
		resizable: false,
		editable: false,
	};

	return { columns, defaultColDef, rowData };
};
