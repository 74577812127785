import { AssetsFilter } from "@/controls/assetsFilter/AssetsFilter";
import { ShiftPeriodNavigatorControl } from "@dexteel/mesf-core";
import { Button, Grid, makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogbookContext } from "../../context/logbookContext";
import { FILTER_INITIAL_VALUES } from "../../models/FilterParametersInitialValue";
import { SearchFilter } from "./searchFilter";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(1),
		height: "auto",
		margin: "20px 0 0",
		width: "100%",
		overflowX: "hidden",
		paddingRight: 20,
	},
	rowTop: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		marginBottom: theme.spacing(1),
		overflowX: "hidden",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			gap: theme.spacing(1),
		},
	},
	rowBottom: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		overflowX: "hidden",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			gap: theme.spacing(1),
		},
	},
	navigatorSection: {
		width: "100%",
		minWidth: 0,
		overflow: "hidden",
	},
	datePickerContainer: {
		"& .MuiTextField-root": {
			minWidth: 0,
			width: "100%",
		},
		"& .MuiOutlinedInput-root": {
			minWidth: 0,
			width: "100%",
		},
	},
	leftSide: {
		flex: "0 0 50%",
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(1),
		maxWidth: "50%",
		overflow: "hidden",
		minWidth: 0,
		padding: 0, // Eliminamos el padding
		[theme.breakpoints.down("sm")]: {
			flex: "0 0 100%",
			width: "100%",
			maxWidth: "100%",
		},
	},
	rightSide: {
		flex: "0 0 50%",
		display: "flex",
		justifyContent: "flex-end",
		paddingRight: theme.spacing(2),
		maxWidth: "50%",
		[theme.breakpoints.down("sm")]: {
			flex: "0 0 100%",
			width: "100%",
			maxWidth: "100%",
			padding: 0,
			justifyContent: "stretch",
		},
	},
	filterControls: {
		display: "flex",
		alignItems: "center",
		gap: theme.spacing(1),
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			width: "100%",
			margin: 0,
		},
	},
	assetFilter: {
		width: "180px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		"& .MuiFormControl-root": {
			height: "40px",
			width: "100%",
		},
		"& .MuiInputBase-root": {
			height: "40px",
		},
		"& .MuiOutlinedInput-root": {
			height: "40px",
		},
	},
	searchFilter: {
		flex: "1 1 auto",
		minWidth: 0,
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
		"& .MuiFormControl-root": {
			height: "40px",
			width: "100%",
		},
		"& .MuiInputBase-root": {
			height: "40px",
		},
		"& .MuiOutlinedInput-root": {
			height: "40px",
		},
	},
	button: {
		height: "40px",
		whiteSpace: "nowrap",
		padding: "6px 16px",
		minWidth: "100px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	btnExcel: {
		backgroundColor: "#217346",
		color: "white",
		"&:hover": {
			backgroundColor: "#1e6e3e",
		},
		height: "40px",
		whiteSpace: "nowrap",
		minWidth: "120px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	navigatorWrapper: {
		width: "100%",
		overflowX: "hidden",
		paddingRight: 0,
	},
}));

type Props = {
	searchEntries: Function;
};

export const Filters = ({ searchEntries }: Props) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const {
		state: {
			FilterParameters: { AssetId, SearchShiftParameters },
			exportExcel,
		},
		actions: {
			setExportExcel,
			setFilter,
			setFilterAssetId,
			setFilterSearchShiftParameters,
		},
	} = useLogbookContext();

	const handleSearch = () => {
		searchEntries();
	};

	const onKeyDown = (e: any) => {
		if (e.keyCode === 13) {
			searchEntries();
		}
	};

	const handleReset = async () => {
		setFilter(FILTER_INITIAL_VALUES);
		setFilterSearchShiftParameters({
			StartShiftId: null,
			EndShiftId: null,
		});
		navigate("/logbook-report");
	};

	useEffect(() => {
		searchEntries();
	}, [SearchShiftParameters, AssetId]);

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12} className={classes.rowTop}>
				<div className={classes.leftSide}>
					<div className={classes.navigatorWrapper}>
						<ShiftPeriodNavigatorControl
							onChange={setFilterSearchShiftParameters}
							value={SearchShiftParameters}
						/>
					</div>
				</div>
				<div className={classes.rightSide} style={{ paddingRight: 0 }}>
					<Button
						variant="contained"
						startIcon={<GetAppIcon />}
						onClick={() => setExportExcel(!exportExcel)}
						className={classes.btnExcel}
					>
						Export Excel
					</Button>
				</div>
			</Grid>

			<Grid item xs={12} className={classes.rowBottom}>
				<div className={classes.leftSide}>
					<div className={classes.filterControls}>
						<div className={classes.assetFilter}>
							<AssetsFilter
								title="Asset"
								value={AssetId}
								onChange={(newValue) => {
									setFilterAssetId(newValue);
								}}
							/>
						</div>

						<div className={classes.searchFilter}>
							<SearchFilter onKeyDown={onKeyDown} />
						</div>

						<Button
							variant="contained"
							color="primary"
							startIcon={<SearchIcon />}
							onClick={handleSearch}
							className={classes.button}
						>
							Search
						</Button>

						<Button
							variant="contained"
							color="default"
							startIcon={<RefreshIcon />}
							onClick={handleReset}
							className={classes.button}
						>
							Reset
						</Button>
					</div>
				</div>
				<div className={classes.rightSide} />
			</Grid>
		</Grid>
	);
};
