import { dxtToLocalServerTime, getMomentTz } from "@dexteel/mesf-core";
import { Divider, Grid, Typography } from "@material-ui/core";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { useEffect, useState } from "react";
import {
	LazyBackDropLoading,
	LazyLoading,
} from "../../../controls/LazyLoading";
import { DynamicGenericReportFilter } from "../models/GenericReportFilter";
import { GenericReportHeader } from "../models/GenericReportHeader";
import { GenericReportFilterDataSource } from "../models/GenericReportMetadataFilter";
import {
	getGenericReportByCode,
	getGenericReportData,
} from "../repositories/genericReport";
import { useGenericReportContext } from "./GenericReportContext";
import { ReportFilterPanel } from "./components/ReportFilterPanel";
import { ReportTable } from "./components/ReportTable";

const moment = getMomentTz();

// functions
const getTimeOfDay = (date: Date, init: boolean): Date => {
	const newDate = moment(date).startOf("day");
	if (!init) {
		newDate.endOf("day");
	}
	return newDate.toDate();
};
export const GenericReportPage = () => {
	const { state, actions } = useGenericReportContext();
	const [gridApi, setGridApi] = useState<GridApi | null>(null);
	const [columnHeaders, setColumnHeaders] = useState<
		GenericReportHeader[] | null
	>(null);

	const [rows, setRows] = useState<any[]>([]);
	const [firstLoad, setFirstLoad] = useState(false);
	const [reportFilters, setReportFilters] =
		useState<DynamicGenericReportFilter>({
			startDate: moment.utc().subtract(1, "days").toDate(),
			endDate: moment.utc().toDate(),
			showStartDate: false,
			showEndDate: false,
			onLoad: false,
		});
	const updateFilters = (newValue: DynamicGenericReportFilter) => {
		setReportFilters(newValue);
	};
	//Url parameters
	const setUrlParameters = () => {
		const paths = location.pathname.split("/");
		let newUrl =
			(paths[0] !== undefined && paths[0] !== "/" ? `${paths[0]}` : ``) +
			(paths[1] !== undefined && paths[1] !== "/" ? `/${paths[1]}` : ``);

		newUrl += `/${state.reportCode}`;
		if (reportFilters.showStartDate)
			newUrl += `/${dxtToLocalServerTime(reportFilters.startDate, "yyyyMMdd")}`;
		if (reportFilters.showEndDate)
			newUrl += `/${dxtToLocalServerTime(reportFilters.endDate, "yyyyMMdd")}`;
		Object.keys(reportFilters).forEach((key) => {
			if (
				key !== "startDate" &&
				key !== "endDate" &&
				key !== "showStartDate" &&
				key !== "showEndDate" &&
				key !== "onLoad"
			)
				newUrl += `/${
					reportFilters[key] === "" ||
					reportFilters[key] === null ||
					reportFilters[key] === undefined
						? "0"
						: reportFilters[key]
				}`;
		});
		window.history.replaceState(null, "", newUrl);
	};
	//Refresh data
	const refreshData = async () => {
		setUrlParameters();
		await getDataFromAPI();
	};
	//API call
	const getReportInfoFromAPI = async () => {
		actions.setIsLoadingPage(true);
		const resp = await getGenericReportByCode(
			state.reportCode === null ? "" : state.reportCode,
		);
		if (
			resp.ok &&
			resp.data.tables.length > 0 &&
			resp.data.tables[0].rows.length > 0
		) {
			actions.setReportInfo(resp.data.tables[0].rows[0]);
		} else {
			console.log("something happened 1");
		}
		if (resp.ok && resp.data.tables.length > 1) {
			actions.setReportMetadataFilters(resp.data.tables[1].rows);
		} else {
			actions.setReportMetadataFilters([]);
		}
		const filterSources: GenericReportFilterDataSource[][] = [];
		for (let i = 2; i < resp.data.tables.length; i++) {
			filterSources.push(resp.data.tables[i].rows);
		}
		actions.setFilterSources(filterSources);
		actions.setIsLoadingPage(false);
	};
	const getDataFromAPI = async () => {
		actions.setIsLoadingGrid(true);
		const resp = await getGenericReportData(
			{
				...reportFilters,
				startDate: getTimeOfDay(reportFilters.startDate, true),
				endDate: getTimeOfDay(reportFilters.endDate, false),
			},
			state.reportInfo?.DataSource ?? "",
		);
		if (resp.ok && resp.data.tables.length > 0) {
			setRows(resp.data.tables[0].rows);
		} else {
			console.log("something happened 2");
		}
		if (resp.ok && resp.data.tables.length > 1)
			setColumnHeaders(resp.data.tables[1].rows);
		actions.setIsLoadingGrid(false);
	};
	// useEffects
	useEffect(() => {
		// Load report info
		(async () => {
			if (state.reportCode !== "" && state.reportCode !== null)
				await getReportInfoFromAPI();
		})();
	}, [state.reportCode]);
	useEffect(() => {
		// Load default filters
		const tempFilters: DynamicGenericReportFilter = {
			...reportFilters,
			showStartDate: state.reportInfo?.HasStartDate ?? false,
			showEndDate: state.reportInfo?.HasEndDate ?? false,
			onLoad: false,
		};
		setReportFilters(tempFilters);
	}, [state.reportInfo]);
	useEffect(() => {
		// Load other filters if the report has them
		if (state.reportMetadataFilters === null) return;
		const tempFilters: DynamicGenericReportFilter = {
			...reportFilters,
			onLoad: true,
		};
		state.reportMetadataFilters?.forEach((item) => {
			tempFilters[item.Parameter] = "";
		});
		setReportFilters(tempFilters);
	}, [state.reportMetadataFilters]);
	useEffect(() => {
		// call refreshData for first time and load from url parameters
		if (reportFilters.onLoad === true) {
			const tempFilters: DynamicGenericReportFilter = {
				...reportFilters,
				onLoad: false,
			};
			// Load from url parameters
			let indexParam = 1; //Starts with 1, 0 for reportCode
			Object.keys(tempFilters).forEach((key) => {
				if (
					key === "showStartDate" ||
					key === "showEndDate" ||
					key === "onLoad" ||
					key === "refreshData"
				) {
				} else if (
					key === "startDate" &&
					tempFilters.showStartDate &&
					state.reportParameters[indexParam] !== undefined
				) {
					const momentDate = moment(
						state.reportParameters[indexParam],
						"YYYYMMDD",
					);
					if (momentDate.isValid()) {
						tempFilters[key] = momentDate.toDate();
					}
					indexParam++;
				} else if (
					key === "endDate" &&
					tempFilters.showEndDate &&
					state.reportParameters[indexParam] !== undefined
				) {
					const momentDate = moment(
						state.reportParameters[indexParam],
						"YYYYMMDD",
					);
					if (momentDate.isValid()) {
						tempFilters[key] = momentDate.toDate();
					}
					indexParam++;
				} else if (state.reportParameters[indexParam] !== undefined) {
					tempFilters[key] = state.reportParameters[indexParam];
					indexParam++;
				} else if (key !== "startDate" && key !== "endDate") {
					tempFilters[key] = "0";
					indexParam++;
				}
			});
			setReportFilters(tempFilters);
			setFirstLoad(true);
		}
	}, [reportFilters]);
	useEffect(() => {
		(async () => {
			if (firstLoad && gridApi) {
				actions.setIsLoadingBackground(true);
				gridApi.showLoadingOverlay();
				await refreshData();
				actions.setIsLoadingBackground(false);
				gridApi.hideOverlay();
			}
		})();
	}, [firstLoad, gridApi]);
	if (!state.isLoadingPage) {
		return (
			<>
				<LazyBackDropLoading open={state.isLoadingBackground} />
				<Grid container justifyContent="center" alignItems="center">
					<Grid item md={12} xs={12} style={{ margin: 0, padding: "15px" }}>
						<Grid item md={12} xs={12}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<Typography variant="h5" style={{ fontWeight: 600 }}>
									{state.reportInfo?.Title}
								</Typography>
								{/* <Button
									variant="contained"
									color="primary"
									onClick={refreshData}
								>
									Apply
								</Button> */}
							</Grid>
						</Grid>
						<ReportFilterPanel
							metadataFilters={state.reportMetadataFilters ?? []}
							filters={reportFilters}
							filterSources={state.filterSources ?? []}
							updateFilters={updateFilters}
							refreshData={refreshData}
							onExcelExport={() => {
								gridApi?.exportDataAsExcel({
									fileName: state.reportInfo?.Title,
								});
							}}
						/>
						<Divider />
						<ReportTable
							headers={columnHeaders ?? []}
							rows={rows}
							onGridReady={(event: GridReadyEvent) => {
								setGridApi(event.api);
							}}
						/>
					</Grid>
				</Grid>
			</>
		);
	}
	return <LazyLoading />;
};
